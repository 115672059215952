import { uploaded } from 'api/media'
import Tooltip from 'components/Tooltip'
import { CloudinaryUploadWidgetContext } from 'contexts/CloudinaryUploadWidgetContext'
import { MediaContext } from 'contexts/MediaContext'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'

function MediaUpload({ label, folderId }) {
  const { state, dispatch, reloadMeta } = useContext(MediaContext)
  const { openUploadWidget } = useContext(CloudinaryUploadWidgetContext)

  const showUploadWidget = () => {
    openUploadWidget((error, result) => {
      if (!error && result && result.event === 'success') {
        uploaded({
          asset_id: result.info.asset_id,
          public_id: result.info.public_id,
          path: result.info.path,
          url: result.info.url,
          thumbnail_url: result.info.thumbnail_url,
          original_filename: result.info.original_filename,
          resource_type: result.info.resource_type,
          format: result.info.format,
          width: result.info.width,
          height: result.info.height,
          bytes: result.info.bytes,
          folder_id: folderId,
        }).then(res => {
          dispatch({
            type: 'ADD_TO_DATA',
            payload: {
              id: res.data.id,
              resource_type: res.data.resource_type,
              public_id: res.data.public_id,
              filename: res.data.filename,
              width: res.data.width,
              height: res.data.height,
            },
          })
          reloadMeta()
        })
      }
    })
  }

  if (Math.round(state.meta.usage.used) >= state.meta.usage.limit) {
    return null
  }

  return (
    <Tooltip label='Upload new media files'>
      <Button variant='success' onClick={showUploadWidget}>
        {label}
      </Button>
    </Tooltip>
  )
}

MediaUpload.propTypes = {
  label: PropTypes.string,
  folderId: PropTypes.string,
}

MediaUpload.defaultProps = {
  label: 'Upload',
  folderId: '',
}

export default MediaUpload
