import { deletePlaylist, duplicate, list, setFolder } from 'api/playlists'
import CreateFolder from 'components/create-folder/CreateFolder'
import CreateADigitalDisplay from 'components/CreateADigitalDisplay'
import DeleteFolder from 'components/delete-folder/DeleteFolder'
import DeleteModal from 'components/DeleteModal'
import LargeHeader from 'components/headers/LargeHeader'
import ListFolders from 'components/list-folders/ListFolders'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import PreviewModal from 'components/PreviewModal'
import Toolbar, { ToolbarGroup } from 'components/toolbar/Toolbar'
import Tooltip from 'components/Tooltip'
import UpdateFolder from 'components/update-folder/UpdateFolder'
import { useEffect, useState } from 'react'
import { Badge, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import {
  ArrowLeftCircle,
  ClipboardX,
  Display,
  Folder,
  House,
  Pencil,
  Trash,
} from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { Link, useParams } from 'react-router-dom'
import DuplicateModal from './DuplicateModal'
import styles from './Playlists.module.scss'

function Playlists(props) {
  const [loading, setLoading] = useState(true)
  const [playlists, setPlaylists] = useState({})
  const [previewUrl, setPreviewUrl] = useState('')
  const [previewOrientation, setPreviewOrientation] = useState('landscape')
  const [previewOpen, setPreviewOpen] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  const [duplicating, setDuplicating] = useState(false)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [duplicateId, setDuplicateId] = useState('')
  const [duplicateIndex, setDuplicateIndex] = useState('')
  const [duplicateError, setDuplicateError] = useState('')

  const { folderId } = useParams()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const res = await list(15, 1, folderId)
      setPlaylists(res)
      setLoading(false)
    }

    fetchData()
  }, [folderId])

  const handleClickPreview = (url, orientation) => {
    setPreviewUrl(url)
    setPreviewOrientation(orientation)
    setPreviewOpen(true)
  }

  const handleOpenDuplicateDialog = (id, index) => {
    setDuplicateId(id)
    setDuplicateIndex(index)
    setDuplicateOpen(true)
  }

  const handleCloseDuplicateDialog = () => {
    setDuplicateOpen(false)
  }

  const handleDuplicate = async id => {
    setDuplicateError(false)
    setDuplicating(true)
    const res = await duplicate(id)

    if (res.success) {
      const items = await list()
      setPlaylists(items)
      setDuplicateOpen(false)
    } else {
      setDuplicateError(true)
    }

    setDuplicating(false)
  }

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deletePlaylist(id)

    if (success) {
      const res = await list()
      setPlaylists(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  const reloadData = async () => {
    const res = await list(15, 1, folderId)
    setPlaylists(res)
  }

  const handleChangeFolder = (playlistId, folderId) => {
    const newPlaylists = { ...playlists }
    const newData = playlists.data.filter(x => x.id !== playlistId)
    newPlaylists.data = newData
    setPlaylists(newPlaylists)
    setFolder({ playlist_id: playlistId, folder_id: folderId })
  }

  if (loading) {
    return <Loader />
  }

  if (
    playlists.data.length === 0 &&
    playlists.meta.folders.length === 0 &&
    playlists.meta.folder.id === ''
  ) {
    return (
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <CreateADigitalDisplay />
      </div>
    )
  }

  return (
    <Container>
      <LargeHeader>Playlists</LargeHeader>

      <PlaylistsToolbar
        state={playlists}
        parentFolderId={
          playlists.meta.folder.id !== '' ? playlists.meta.parent_folder.id : undefined
        }
        reloadData={reloadData}
      />

      <ListFolders
        folders={playlists.meta.child_folders.map(x => {
          x.otherProps = { as: Link, to: `/playlists/${x.id}` }
          return x
        })}
      />

      <Panel>
        <Table responsive='sm'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Slides</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {playlists.data.map((x, index) => (
              <tr key={x.id}>
                <td>{x.name}</td>
                <td>{x.slide_count}</td>
                <td align='right' style={{ minWidth: '335px' }}>
                  <Tooltip label='Change folder'>
                    <DropdownButton
                      size='xs'
                      className={`${styles.dropdown} mr-1 d-inline-block`}
                      name='display-images-for'
                      title={<Folder />}>
                      {playlists.meta.folders.map(folder => (
                        <Dropdown.Item
                          key={`${x.id}-${folder.id}`}
                          onClick={() => handleChangeFolder(x.id, folder.id)}>
                          {folder.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Tooltip>
                  <Tooltip label='Edit Playlist'>
                    <Button
                      className='mr-1 px-2'
                      variant='primary'
                      size='sm'
                      as={Link}
                      to={`/playlists/view/${x.id}`}>
                      <Pencil />
                    </Button>
                  </Tooltip>
                  <Tooltip label='Duplicate Playlist'>
                    <Button
                      className='mr-1 px-2'
                      variant='primary'
                      size='sm'
                      as={Link}
                      onClick={() => handleOpenDuplicateDialog(x.id, index)}>
                      <ClipboardX />
                    </Button>
                  </Tooltip>
                  <Tooltip label='Preview Playlist'>
                    <Button
                      className='mr-1 px-2'
                      variant='primary'
                      size='sm'
                      onClick={() => handleClickPreview(x.preview_url, x.orientation)}>
                      <Display />
                    </Button>
                  </Tooltip>
                  {x.is_in_use ? (
                    <Tooltip label='Playlist used by display'>
                      <Button variant='danger' className='px-2' size='sm' disabled>
                        <Trash />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip label='Delete Playlist'>
                      <Button
                        variant='danger'
                        className='px-2'
                        size='sm'
                        onClick={() => handleOpenDeleteDialog(x.id, index)}>
                        <Trash />
                      </Button>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>

      <PreviewModal
        open={previewOpen}
        onHide={() => {
          setPreviewOpen(false)
        }}
        url={previewUrl}
        orientation={previewOrientation}
      />

      <DuplicateModal
        show={duplicateOpen}
        onHide={handleCloseDuplicateDialog}
        duplicating={duplicating}
        onDuplicate={() => handleDuplicate(duplicateId, duplicateIndex)}
        errorMessage={duplicateError}
      />

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

function PlaylistsToolbar({ state, parentFolderId, reloadData }) {
  return (
    <Toolbar>
      <ToolbarGroup>
        <div className='mr-1 mb-1'>
          <Tooltip label='Create a new playlist'>
            <Button variant='success' as={Link} to='/create'>
              Create
            </Button>
          </Tooltip>
        </div>

        {parentFolderId !== undefined && (
          <>
            <Tooltip label='Media Home'>
              <Button
                as={Link}
                to='/playlists'
                variant='primary'
                className='mr-1 px-2 mb-1'>
                <House />
              </Button>
            </Tooltip>

            <Tooltip label='Back'>
              <Button
                as={Link}
                to={`/playlists/${parentFolderId}`}
                variant='primary'
                className='mr-1 px-2 mb-1'>
                <ArrowLeftCircle />
              </Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>

      <ToolbarGroup>
        {state.meta.folder.id !== '' && (
          <Badge variant='secondary' className='mr-1 px-2 d-flex align-items-center mb-1'>
            {state.meta.folder.name}
          </Badge>
        )}

        <CreateFolder
          type='PLAYLIST'
          parentFolderId={state.meta.folder.id}
          onSuccess={reloadData}
        />

        {state.meta.folder.id !== '' && (
          <>
            <UpdateFolder
              folderId={state.meta.folder.id}
              defaultName={state.meta.folder.name}
              onSuccess={reloadData}
            />

            <DeleteFolder
              folderId={state.meta.folder.id}
              name={state.meta.folder.name}
              onSuccess={reloadData}
            />
          </>
        )}
      </ToolbarGroup>
    </Toolbar>
  )
}

export default Playlists
