import { me } from 'api/auth'
import React, { useEffect, useState } from 'react'

const AuthContext = React.createContext()
const WithAuth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState(null)
  const [twoFactorAuthBanner, setTwoFactorAuthBanner] = useState(false)

  const fetchData = async () => {
    const user = await me()
    if (user) {
      setAuthenticated(true)
      setUser(user)
      setTwoFactorAuthBanner(!user.two_factor)
    }
    setAuthenticating(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const defaultContext = {
    authenticating,
    authenticated,
    setAuthenticated,
    fetchData,
    user,
    setUser,
    twoFactorAuthBanner,
    setTwoFactorAuthBanner,
  }
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

export { AuthContext }
export default WithAuth
