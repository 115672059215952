import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import BsTooltip from 'react-bootstrap/Tooltip'
import PropTypes from 'prop-types'

function Tooltip({ label, placement, children }) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 50, hide: 50 }}
      overlay={<BsTooltip>{label}</BsTooltip>}>
      {children}
    </OverlayTrigger>
  )
}

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  placement: PropTypes.string,
}

Tooltip.defaultProps = {
  placement: 'top',
}

export default Tooltip
