import { create } from 'api/distributors'
import LoaderButton from 'components/LoaderButton'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

function Create(props) {
  const [updating, setUpdating] = useState(false)
  const [values, setValues] = useState({
    name: '',
    user_first_name: '',
    user_last_name: '',
    user_email: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})

    setUpdating(true)
    const body = {
      name: values.name,
      user: {
        first_name: values.user_first_name,
        last_name: values.user_last_name,
        email: values.user_email,
      },
    }

    const res = await create(body)
    if (res.success) {
      props.history.push({ pathname: '/distributors' })
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setUpdating(false)
  }

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <Form onSubmit={handleSubmit}>
              <h1>Create Distributor</h1>

              <div className='mb-5'>
                <Form.Group>
                  <Form.Label>Distributor Name</Form.Label>
                  <Form.Control
                    isInvalid={errors.hasOwnProperty('name')}
                    name='name'
                    placeholder='Enter a name for this distributor'
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.hasOwnProperty('name') && (
                    <Form.Text className='text-danger'>{errors.name}</Form.Text>
                  )}
                </Form.Group>
              </div>

              <h3>Initial User</h3>
              <Alert variant='info'>
                Distributor's can manage their own users but you'll need to setup the
                first one below.
              </Alert>

              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  isInvalid={errors.hasOwnProperty('user.first_name')}
                  name='user_first_name'
                  placeholder="Enter the user's first name"
                  value={values.user_first_name}
                  onChange={handleChange}
                />
                {errors.hasOwnProperty('user.first_name') && (
                  <Form.Text className='text-danger'>
                    {errors['user.first_name']}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  isInvalid={errors.hasOwnProperty('user.last_name')}
                  name='user_last_name'
                  placeholder="Enter the user's last name"
                  value={values.user_last_name}
                  onChange={handleChange}
                />
                {errors.hasOwnProperty('user.last_name') && (
                  <Form.Text className='text-danger'>
                    {errors['user.last_name']}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  isInvalid={errors.hasOwnProperty('user.email')}
                  name='user_email'
                  placeholder="Enter the user's email"
                  value={values.user_email}
                  onChange={handleChange}
                />
                {errors.hasOwnProperty('user.email') && (
                  <Form.Text className='text-danger'>{errors['user.email']}</Form.Text>
                )}
              </Form.Group>

              {errorMessage && (
                <Alert className='mt-4' variant='danger'>
                  {errorMessage}
                </Alert>
              )}

              <Button
                variant='secondary'
                className='mt-4 mr-2'
                onClick={() => props.history.goBack()}>
                Cancel
              </Button>

              <LoaderButton
                className='mt-4'
                label='Create'
                loading={updating}
                variant='success'
                type='submit'
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Create
