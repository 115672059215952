import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 15, page = 1, ownerType = null, ownerId = null) {
  let url = `/iapi/users?per_page=${limit}&page=${page}`

  if (ownerId) {
    url += `&ownerType=${ownerType}&ownerId=${ownerId}`
  }

  const result = await fetch(config.API_URL + url, { credentials: 'include' })
  return await result.json()
}

export async function get(id) {
  const result = await fetch(config.API_URL + `/iapi/users/${id}`, {
    credentials: 'include',
  })
  return await result.json()
}

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/users`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 201,
    data: await res.json(),
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/users/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteUser(id) {
  const res = await fetch(config.API_URL + `/iapi/users/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}
