import { create } from 'api/folders'
import LoaderButton from 'components/LoaderButton'
import Tooltip from 'components/Tooltip'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FolderPlus } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

function CreateFolder({ type, parentFolderId, onSuccess }) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const res = await create({ type: type, parent_folder_id: parentFolderId, name: name })
    if (res.success) {
      setName('')
      setLoading(false)
      if (onSuccess !== undefined) {
        setLoading(false)
        setOpen(false)
        onSuccess()
      }
    } else {
      setError(res.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Tooltip label='Create folder'>
        <Button variant='primary' className='mr-1 px-2 mb-1' onClick={handleOpen}>
          <FolderPlus />
        </Button>
      </Tooltip>

      <Modal show={open} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className='mb-4'>
              <Form.Group>
                <Form.Control
                  name='name'
                  placeholder='Folder name'
                  value={name}
                  onChange={e => {
                    setError('')
                    setName(e.target.value)
                  }}
                />
              </Form.Group>
            </Form.Group>

            {error && (
              <Alert className='mt-4' variant='danger'>
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <LoaderButton
              label='Create'
              loading={loading}
              variant='primary'
              type='submit'
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

CreateFolder.propTypes = {
  type: PropTypes.oneOf(['MEDIA', 'PLAYLIST', 'SCREEN']).isRequired,
  parentFolderId: PropTypes.string,
  onSuccess: PropTypes.func,
}

CreateFolder.defaultProps = {
  parentFolderId: '',
}

export default CreateFolder
