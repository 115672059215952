import PropTypes from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import { FolderFill } from 'react-bootstrap-icons'

function ListFolders({ folders }) {
  if (folders.length === 0) return null

  return (
    <div className='mb-4'>
      <ListGroup>
        {folders.map(x => (
          <ListGroup.Item
            key={`folder-${x.id}`}
            action
            className='d-flex justify-content-between align-items-start'
            {...x.otherProps}>
            <div className='pl-4 mr-auto'>
              <FolderFill className='mt-n1 mr-1' /> {x.name}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

ListFolders.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

ListFolders.defaultProps = {
  otherProps: {},
}

export default ListFolders
