import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function ResellerHelp(props) {
  return (
    <Tooltip label='Limit which resellers will see this package, leave empty to allow it for all resellers.'>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default ResellerHelp
