import { get, update } from 'api/customers'
import { list as getPackages } from 'api/packages'
import LoaderButton from 'components/LoaderButton'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'
import DisplayLinkTypeHelp from './help/DisplayLinkTypeHelp'
import DisplaysMutedHelp from './help/DisplaysMutedHelp'

function Update(props) {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [packages, setPackages] = useState()
  const [updating, setUpdating] = useState(false)
  const [values, setValues] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const [_customer, _packages] = await Promise.all([get(id), getPackages(0, 1000)])

      setValues({
        name: _customer.data.name,
        package_id: _customer.data.package.id,
        display_link_type: _customer.data.display_link_type,
        displays_muted: _customer.data.displays_muted ? 1 : 0,
      })

      setPackages(_packages.data.map(x => ({ id: x.id, name: x.name })))

      setLoading(false)
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})

    setUpdating(true)
    const body = {
      name: values.name,
      package_id: values.package_id,
      display_link_type: values.display_link_type,
      displays_muted: values.displays_muted,
    }

    const res = await update(id, body)
    if (res.success) {
      props.history.push({ pathname: '/customers' })
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setUpdating(false)
  }

  if (loading) {
    return null
  }

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <Form onSubmit={handleSubmit}>
              <h1>Update Customer</h1>

              <div className='mb-5'>
                <Form.Group>
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    isInvalid={errors.hasOwnProperty('name')}
                    name='name'
                    placeholder='Enter a name for this customer'
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.hasOwnProperty('name') && (
                    <Form.Text className='text-danger'>{errors.name}</Form.Text>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Package</Form.Label>
                  <Form.Control
                    isInvalid={errors.hasOwnProperty('package_id')}
                    name='package_id'
                    as='select'
                    value={values.package_id}
                    onChange={handleChange}>
                    <option value=''>Please select</option>
                    {packages.map(x => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.hasOwnProperty('package_id') && (
                    <Form.Text className='text-danger'>{errors.package_id}</Form.Text>
                  )}
                </Form.Group>

                <Form.Group>
                  <DisplayLinkTypeHelp /> <Form.Label>Display Link Type</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Display Link Type'
                    name='display_link_type'
                    isInvalid={errors.hasOwnProperty('display_link_type')}
                    value={values.display_link_type}
                    onChange={handleChange}>
                    <option value='download'>Download</option>
                    <option value='browser'>Browser</option>
                    {errors.hasOwnProperty('display_link_type') && (
                      <Form.Text className='text-danger'>
                        {errors.display_link_type}
                      </Form.Text>
                    )}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <DisplaysMutedHelp /> <Form.Label>Displays Muted</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Displays Muted'
                    name='displays_muted'
                    isInvalid={errors.hasOwnProperty('displays_muted')}
                    value={values.displays_muted}
                    onChange={handleChange}>
                    <option value='1'>Yes</option>
                    <option value='0'>No</option>
                    {errors.hasOwnProperty('displays_muted') && (
                      <Form.Text className='text-danger'>
                        {errors.displays_muted}
                      </Form.Text>
                    )}
                  </Form.Control>
                </Form.Group>
              </div>

              {errorMessage && (
                <Alert className='mt-4' variant='danger'>
                  {errorMessage}
                </Alert>
              )}

              <Button
                variant='secondary'
                className='mt-4 mr-2'
                onClick={() => props.history.goBack()}>
                Cancel
              </Button>

              <LoaderButton
                className='mt-4'
                label='Update'
                loading={updating}
                variant='success'
                type='submit'
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Update
