import MediaContext from 'contexts/MediaContext'
import React from 'react'
import { Container } from 'react-bootstrap'
import MediaContent from './MediaContent'

function Media() {
  return (
    <MediaContext>
      <Container>
        <MediaContent />
      </Container>
    </MediaContext>
  )
}

export default Media
