import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 15, page = 1) {
  const result = await fetch(
    config.API_URL + `/iapi/resellers?per_page=${limit}&page=${page}`,
    { credentials: 'include' }
  )
  return await result.json()
}

export async function get(id) {
  const result = await fetch(config.API_URL + `/iapi/resellers/${id}`, {
    credentials: 'include',
  })
  return await result.json()
}

export async function dashboard(hash) {
  const result = await fetch(
    config.API_URL + `/iapi/resellers/device-dashboard/${hash}`,
    {
      credentials: 'include',
    }
  )
  return await result.json()
}

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/resellers`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 201,
    data: await res.json(),
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/resellers/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteReseller(id) {
  const res = await fetch(config.API_URL + `/iapi/resellers/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}
