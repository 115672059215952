import { deletePackage, list } from 'api/packages'
import DeleteModal from 'components/DeleteModal'
import LargeHeader from 'components/headers/LargeHeader'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import RequestNewPackage from 'components/RequestNewPackage'
import Tooltip from 'components/Tooltip'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

function Packages(props) {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [packages, setPackages] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await list()
      setPackages(res)
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deletePackage(id)

    if (success) {
      const res = await list()
      setPackages(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <LargeHeader>Packages</LargeHeader>
      {user.role === 'system_user' && (
        <Tooltip label='Create a new package'>
          <Button className='mb-3' variant='success' as={Link} to='/packages/create'>
            Create
          </Button>
        </Tooltip>
      )}

      {user.role !== 'system_user' && (
        <div className='mb-3'>
          <RequestNewPackage />
        </div>
      )}

      <Panel>
        {packages.data.length === 0 ? (
          <div>No packages created yet.</div>
        ) : (
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>User Limit</th>
                <th>Display Limit</th>
                <th>Media Limit</th>
                {user.role !== 'system_user' && <th>Monthly Buy</th>}
                <th>Monthly Sell</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {packages.data.map((x, index) => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  <td>{x.user_limit}</td>
                  <td>{x.screen_limit}</td>
                  <td>{x.media_limit_mb}MB</td>
                  {user.role !== 'system_user' && <td>£{x.monthly_buy_pence / 100}</td>}
                  <td>£{x.monthly_sell_pence / 100}</td>
                  <td align='right' style={{ minWidth: '90px' }}>
                    <Tooltip label='Edit Package'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/packages/update/${x.id}`}>
                        <Pencil />
                      </Button>
                    </Tooltip>
                    {user.role === 'system_user' && (
                      <>
                        {x.is_in_use ? (
                          <Tooltip label='Package used by customer'>
                            <Button variant='danger' className='px-2' size='sm' disabled>
                              <Trash />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip label='Delete Package'>
                            <Button
                              variant='danger'
                              className='px-2'
                              size='sm'
                              onClick={() => handleOpenDeleteDialog(x.id, index)}>
                              <Trash />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Panel>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

export default Packages
