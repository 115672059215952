export function getUrl(cloudName, resourceType, publicId) {
  return `https://res.cloudinary.com/${cloudName}/${resourceType}/upload/${publicId}`
}

export function getSimpleTransformationUrl(cloudName, resourceType, publicId, width) {
  return `https://res.cloudinary.com/${cloudName}/${resourceType}/upload/w_${width}/${publicId}.png`
}

export function getTransformationUrl(
  cloudName,
  resourceType,
  publicId,
  width,
  height,
  format
) {
  return `https://res.cloudinary.com/${cloudName}/${resourceType}/upload/w_${width},h_${height},c_fill/${publicId}.${format}`
}
