import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function MediaLimitHelp(props) {
  return (
    <Tooltip label='The customer will be able to upload media content to use with their displays. Limit the MB storage this customer can store.'>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default MediaLimitHelp
