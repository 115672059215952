import { AuthContext } from 'contexts/AuthContext'
import { getTransformationUrl } from 'helpers/cloudinary'
import PropTypes from 'prop-types'
import React, { useContext, useRef } from 'react'
import Badge from 'react-bootstrap/Badge'
import styles from './MediaItem.module.scss'

function MediaItem({
  resource,
  height = null,
  width = null,
  resourceOverlay,
  selected,
  onSelect,
  displayBadges,
  overlay,
}) {
  const { user } = useContext(AuthContext)
  const videoRef = useRef(null)
  const videoResource = resource?.resource_type === 'video'

  const handleMouseEnter = e => {
    if (videoResource) {
      videoRef.current.play()
    }
  }
  const handleMouseLeave = e => {
    if (videoResource) {
      videoRef.current.pause()
    }
  }

  if (resource && !['image', 'video', 'iframe'].includes(resource.resource_type)) {
    return null
  }

  const handleSelect = () => {
    onSelect(resource)
  }

  const dimensions = {
    width: resource
      ? Math.round(
          resource.width >= resource.height
            ? 355
            : 355 * (resource.width / resource.height)
        )
      : 355,
    height: resource
      ? Math.round(
          resource.height >= resource.width
            ? 355
            : 355 * (resource.height / resource.width)
        )
      : 355,
  }

  const url = res => {
    return res
      ? getTransformationUrl(
          user.cloud_store.name,
          res.resource_type,
          res.public_id,
          width ?? dimensions.width,
          height ?? dimensions.height,
          videoResource ? 'mp4' : 'png'
        )
      : ''
  }

  const displayFilename = filename => {
    const maxLength = 18

    if (filename.length <= maxLength) {
      return filename
    }

    const extension = filename.substr(filename.length - 3)
    return filename.substring(0, maxLength - 6) + '...' + extension
  }

  return (
    <div
      className={`${!displayBadges && 'd-flex flex-grow-1'} ${
        selected ? styles.itemSelected : styles.itemNotSelected
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelect}>
      <div style={{ position: 'relative', display: 'flex', flexGrow: '1' }}>
        {resource && resource.resource_type === 'iframe' ? (
          <iframe
            style={{ flexGrow: '1' }}
            src={resource.url}
            title='media'
            scrolling='no'
            frameBorder='0'
            className={`${styles.itemContent} ${
              selected ? styles.selected : styles.notSelected
            }`}></iframe>
        ) : (
          <>
            {videoResource ? (
              <>
                <video
                  ref={videoRef}
                  muted
                  className={`${styles.itemContent} ${
                    selected ? styles.selected : styles.notSelected
                  }`}>
                  <source
                    src={url(resource ? resource : resourceOverlay)}
                    type='video/mp4'
                  />
                </video>
              </>
            ) : (
              <img
                src={url(resource ? resource : resourceOverlay)}
                alt='media'
                className={`${styles.itemContent} ${
                  selected ? styles.selected : styles.notSelected
                }`}
              />
            )}
          </>
        )}

        {resourceOverlay && (
          <img
            src={url(resourceOverlay)}
            style={{ position: 'absolute', top: 0, left: 0 }}
            alt='media overlay'
            className={`${styles.itemContent} ${selected ? styles.selected : ''}`}></img>
        )}

        {overlay && <div className={styles.overlay}>{overlay}</div>}
      </div>

      {displayBadges && (
        <React.Fragment>
          <Badge className='mr-2' pill variant='secondary'>
            {resource.resource_type}
          </Badge>
          <Badge className='mr-2' pill variant='secondary'>
            {resource.size_string}
          </Badge>
          <Badge pill variant='secondary'>
            {displayFilename(resource.filename)}
          </Badge>
        </React.Fragment>
      )}
    </div>
  )
}

MediaItem.propTypes = {
  resource: PropTypes.object,
  orientation: PropTypes.string,
  aspectRatio: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  displayBadges: PropTypes.bool,
  overlay: PropTypes.node,
}

MediaItem.defaultProps = {
  orientation: 'landscape',
  aspectRatio: '16:9',
  selected: false,
  onSelect: () => {},
  displayBadges: true,
}

export default MediaItem
