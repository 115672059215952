import React from 'react'
import styles from './SmallHeader.module.scss'
import PropTypes from 'prop-types'

function SmallHeader({ noGutter, children }) {
  return (
    <div className={`${styles.header} ${noGutter ? '' : styles.gutter}`}>{children}</div>
  )
}

SmallHeader.propTypes = {
  noGutter: PropTypes.bool,
}

SmallHeader.defaultProps = {
  noGutter: false,
}

export default SmallHeader
