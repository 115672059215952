import { logout } from 'api/auth'
import Terms from 'containers/terms/Terms'
import { AuthContext } from 'contexts/AuthContext'
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext'
import { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import {
  Box,
  Building,
  Clock,
  CollectionPlay,
  Palette,
  People,
  Pip,
  Speedometer2,
  Tv,
} from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link, NavLink } from 'react-router-dom'
import styles from './Auth.module.scss'

const customerMenuItems = [
  { icon: <Speedometer2 />, label: 'Dashboard', to: '/' },
  { icon: <Tv />, label: 'Displays', to: '/displays' },
  { icon: <Pip />, label: 'Playlists', to: '/playlists' },
  { icon: <Clock />, label: 'Schedules', to: '/schedules' },
  { icon: <Palette />, label: 'Create', to: '/create' },
  { icon: <CollectionPlay />, label: 'Media', to: '/media' },
  { icon: <People />, label: 'Users', to: '/users' },
]

const resellerMenuItems = [
  { icon: <Speedometer2 />, label: 'Dashboard', to: '/' },
  { icon: <Building />, label: 'Customers', to: '/customers' },
  { icon: <Box />, label: 'Packages', to: '/packages' },
  { icon: <People />, label: 'Admin Users', to: '/users' },
]

const distributorMenuItems = [
  { icon: <Speedometer2 />, label: 'Dashboard', to: '/' },
  { icon: <Building />, label: 'Resellers', to: '/resellers' },
  { icon: <Box />, label: 'Packages', to: '/packages' },
  { icon: <People />, label: 'Admin Users', to: '/users' },
]

const systemUserMenuItems = [
  { icon: <Speedometer2 />, label: 'Dashboard', to: '/' },
  { icon: <Building />, label: 'Distributors', to: '/distributors' },
  { icon: <Box />, label: 'Packages', to: '/packages' },
  { icon: <People />, label: 'Admin Users', to: '/users' },
]

function Layout({ component: C, ...props }) {
  const { user, setAuthenticated, setUser, twoFactorAuthBanner } = useContext(AuthContext)
  const { renderBreadcrumbs } = useContext(BreadcrumbsContext)

  const getMenuItems = () => {
    switch (user.role) {
      case 'system_user':
        return systemUserMenuItems
      case 'distributor':
        return distributorMenuItems
      case 'reseller':
        return resellerMenuItems
      default:
        return customerMenuItems
    }
  }

  const handleLogout = async () => {
    setAuthenticated(false)
    if (await logout()) {
      setAuthenticated(false)
      setUser(null)
    }
  }

  return (
    <>
      <div className={styles.main}>
        <div className={`sidenav ${styles.sidenav}`}>
          <div className={styles.sidenavLogo}>
            <img src='/logo.png' alt='Logo' />
          </div>
          {(user.role === 'system_user' ||
            user.terms_agreed ||
            user.hasOwnProperty('leave_impersonation')) && (
            <div className={styles.sidenavItems}>
              {getMenuItems().map(x => (
                <NavLink key={x.label} className={styles.sidenavItem} to={x.to} exact>
                  <div className='d-flex'>
                    <div className={styles.sidenavIcon}>{x.icon}</div> {x.label}
                  </div>
                </NavLink>
              ))}
            </div>
          )}
        </div>

        <div className={styles.content}>
          <Navbar
            variant='dark'
            sticky='top'
            expand='lg'
            className={styles.navbar}
            collapseOnSelect>
            <Container fluid>
              <Navbar.Brand className='d-block d-lg-none'>
                <img src='/logo.png' alt='Logo' />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse id='navbar'>
                <div className='d-none d-lg-block'>{renderBreadcrumbs()}</div>

                <Nav className={`${styles.items} ml-auto`}>
                  {getMenuItems().map(x => (
                    <Nav.Link
                      key={x.label}
                      eventKey={x.label}
                      className='d-block d-lg-none'
                      as={NavLink}
                      to={x.to}
                      exact>
                      {x.label}
                    </Nav.Link>
                  ))}

                  <Nav.Link as={NavLink} to='/my-profile'>
                    My Profile
                  </Nav.Link>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {user.role !== 'system_user' &&
          !user.terms_agreed &&
          !user.hasOwnProperty('leave_impersonation') ? (
            <Terms />
          ) : (
            <>
              {user.hasOwnProperty('leave_impersonation') && (
                <Alert variant='info' className='mb-0'>
                  You are currently{' '}
                  <strong>
                    impersonating {user.first_name} {user.last_name} ({user.owner.name})
                  </strong>
                  .{' '}
                  <a href={user.leave_impersonation}>
                    Click here to return to your main account.
                  </a>
                </Alert>
              )}

              {twoFactorAuthBanner && (
                <Alert variant='info' className='mb-0'>
                  <Link to='my-profile'>
                    Improve your security by clicking here to enable two factor
                    authentication.
                  </Link>
                </Alert>
              )}

              <div className='d-block d-lg-none pl-4'>{renderBreadcrumbs()}</div>

              <Container fluid className='pt-5 pb-5'>
                <C {...props} />
              </Container>
            </>
          )}
        </div>

        <div className={styles.push} />
      </div>
      <div className={styles.footer} style={{ textAlign: 'right' }}>
        <a
          href='https://visivotech.co.uk/wp-content/uploads/2024/05/VISIVO-TECHNOLOGIES-LTD-EULA-GB-Visivotech-3.pdf'
          target='_blank'
          rel='noreferrer'>
          EULA
        </a>
        <a
          href='https://visivotech.co.uk/wp-content/uploads/2024/05/Visivo-Technologies-LTD-PrivacyPolicy.pdf'
          target='_blank'
          rel='noreferrer'>
          Privacy Policy
        </a>
      </div>
    </>
  )
}

export default Layout
