import SmallHeader from 'components/headers/SmallHeader'
import React from 'react'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import styles from './Dashboard.module.scss'
import PropTypes from 'prop-types'

function ActionRow({ title, icon, color, to, aLink, children }) {
  return (
    <div className={styles.actionRow}>
      <div className='d-none d-sm-block'>
        <div
          className='p-3 d-flex align-items-center justify-content-center'
          style={{ backgroundColor: color, borderRadius: '10px', fontSize: '3rem' }}>
          {icon}
        </div>
      </div>
      <div>
        <SmallHeader noGutter>{title}</SmallHeader>
        <div className='text-muted'>{children}</div>
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        {aLink ? (
          <a href={to} target='_blank' rel='noreferrer'>
            <ArrowRightCircle
              style={{ fontSize: '2rem' }}
              className='round-icon-button'
            />
          </a>
        ) : (
          <Link to={to}>
            <ArrowRightCircle
              style={{ fontSize: '2rem' }}
              className='round-icon-button'
            />
          </Link>
        )}
      </div>
    </div>
  )
}

ActionRow.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.string,
  to: PropTypes.string,
  aLink: PropTypes.bool,
}

ActionRow.defaultProps = {
  aLink: false,
}

export default ActionRow
