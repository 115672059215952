import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 15, page = 1, folderId = '') {
  try {
    const result = await fetch(
      config.API_URL + `/iapi/screens?per_page=${limit}&page=${page}&folder=${folderId}`,
      { credentials: 'include' }
    )
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(id) {
  try {
    const result = await fetch(config.API_URL + `/iapi/screens/${id}`, {
      credentials: 'include',
    })
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/screens`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 201,
    data: await res.json(),
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/screens/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteScreen(id) {
  const res = await fetch(config.API_URL + `/iapi/screens/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}

export async function setFolder(body) {
  const res = await fetch(config.API_URL + `/iapi/screens/set-folder`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return res.status === 204
}
