import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function MonthlySellPriceHelp(props) {
  return (
    <Tooltip label='The price to sell this package at.'>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default MonthlySellPriceHelp
