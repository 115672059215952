import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 16, page = 1, folderId = '') {
  const result = await fetch(
    config.API_URL + `/iapi/media?per_page=${limit}&page=${page}&folder=${folderId}`,
    { credentials: 'include' }
  )
  return await result.json()
}

export async function generateSignature(body) {
  const res = await fetch(config.API_URL + `/iapi/media/generate-signature`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return await res.json()
}

export async function uploaded(body) {
  const res = await fetch(config.API_URL + `/iapi/media/uploaded`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return await res.json()
}

export async function deleteMedia(id) {
  const res = await fetch(config.API_URL + `/iapi/media/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}

export async function deleteManyMedia(body) {
  const res = await fetch(config.API_URL + `/iapi/media/delete-many`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return res.status === 204
}

export async function toggleFavourite(id) {
  const res = await fetch(config.API_URL + `/iapi/media/${id}/toggle-favourite`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}

export async function setFolder(body) {
  const res = await fetch(config.API_URL + `/iapi/media/set-folder`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return res.status === 204
}
