import { create } from 'api/slides'
import LoaderButton from 'components/LoaderButton'
import { AuthContext } from 'contexts/AuthContext'
import { CreateContext } from 'contexts/CreateContext'
import { getTransformationUrl } from 'helpers/cloudinary'
import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { Layer, Stage } from 'react-konva'
import styles from './Konva.module.scss'
import Circle from './Layers/Circle'
import Image from './Layers/Image'
import Rectangle from './Layers/Rectangle'
import RegularPolygon from './Layers/RegularPolygon'
import Star from './Layers/Star'
import Text from './Layers/Text'
import Ruler from 'components/ruler/Ruler'

const aspectRatios = {
  '16:9': {
    landscape: { width: 1920, height: 1080 },
    portrait: { width: 1080, height: 1920 },
  },
  '32:9': {
    landscape: { width: 1920, height: 540 },
    portrait: { width: 540, height: 1920 },
  },
  '9:8': {
    landscape: { width: 2160, height: 1920 },
    portrait: { width: 1920, height: 2160 },
  },
}

function Konva({ selectedId, selectShape }) {
  const { user } = useContext(AuthContext)
  const { state, dispatch, playlistId, slideId, history } = useContext(CreateContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [konvaWidth, setKonvaWidth] = useState(0)
  const [konvaHeight, setKonvaHeight] = useState(0)
  const [scaleX, setScaleX] = useState(1)
  const [scaleY, setScaleY] = useState(1)
  const konvaContainerRef = useRef()
  const konvaSizerRef = useRef()
  const stageRef = useRef()

  useEffect(() => {
    const setScales = debounce(async () => {
      const width = konvaSizerRef.current.offsetWidth
      const height = konvaSizerRef.current.offsetHeight
      setKonvaWidth(width)
      setKonvaHeight(height)
      setScaleX(width / aspectRatios[state.aspect_ratio][state.orientation].width)
      setScaleY(height / aspectRatios[state.aspect_ratio][state.orientation].height)
    }, 200)

    let width =
      state.orientation === 'landscape' ? user.cloud_store.width : user.cloud_store.height
    let height =
      state.orientation === 'landscape' ? user.cloud_store.height : user.cloud_store.width

    if (state.aspect_ratio === '32:9') {
      if (state.orientation === 'landscape') {
        height = height / 2
      } else {
        width = width / 2
      }
    } else if (state.aspect_ratio === '9:8') {
      if (state.orientation === 'landscape') {
        width = 2160
        height = 1920
      } else {
        width = 1920
        height = 2160
      }
    }

    const baseLayerUrl = getTransformationUrl(
      user.cloud_store.name,
      state.baseLayer.resource_type,
      state.baseLayer.public_id,
      width,
      height,
      'png'
    )

    konvaContainerRef.current.style['background-color'] = `#000`
    konvaContainerRef.current.style['background-image'] = `url("${baseLayerUrl}")`

    setScales()

    window.addEventListener('resize', setScales)

    return () => {
      window.removeEventListener('resize', setScales)
    }
  }, [state.baseLayer, state.orientation, user.cloud_store, state.aspect_ratio])

  const handleChange = (newAttrs, index) => {
    const newShapes = state.shapes.slice()
    newShapes[index] = newAttrs
    dispatch({ type: 'SET_SHAPES', payload: newShapes })
  }

  const checkDeselect = e => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      selectShape(null)
    }
  }

  const handleSave = async () => {
    setLoading(true)
    setError('')

    const currentScaleX = stageRef.current.scaleX()
    const currentScaleY = stageRef.current.scaleY()

    // set scale to native for download
    stageRef.current.setAttrs({ scaleX: 1, scaleY: 1 })
    const imageData = stageRef.current.toDataURL({
      width: aspectRatios[state.aspect_ratio][state.orientation].width,
      height: aspectRatios[state.aspect_ratio][state.orientation].height,
    })

    // reset scale back
    stageRef.current.setAttrs({ scaleX: currentScaleX, scaleY: currentScaleY })

    const res = await create({
      slide_id: slideId ? slideId : '',
      playlist_id: playlistId ? playlistId : '',
      base_layer_id: state.baseLayer.id,
      image_data: state.shapes.length === 0 ? '' : imageData,
      overlay_data: state.shapes,
      orientation: state.orientation,
      aspect_ratio: state.aspect_ratio,
    })

    if (res.success) {
      history.push({ pathname: `/playlists/view/${res.data.playlist.id}` })
    } else {
      setError(res.message)
    }
    setLoading(false)
  }

  const getSizerClassName = () => {
    if (state.orientation === 'landscape') {
      switch (state.aspect_ratio) {
        case '32:9':
          return styles.sizerLandscape329
        case '9:8':
          return styles.sizerLandscape98
        default:
          return styles.sizerLandscape
      }
    } else {
      switch (state.aspect_ratio) {
        case '32:9':
          return styles.sizerPortrait932
        case '9:8':
          return styles.sizerPortrait89
        default:
          return styles.sizerPortrait
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.konvaContainer} ref={konvaContainerRef}>
        <div className={getSizerClassName()} ref={konvaSizerRef}>
          <div className={styles.content}>
            <Ruler width={konvaWidth} height={konvaHeight} />

            <Stage
              width={konvaWidth}
              height={konvaHeight}
              scaleX={scaleX}
              scaleY={scaleY}
              ref={stageRef}
              onMouseDown={checkDeselect}>
              <Layer>
                {state.shapes.map((shape, index) => {
                  return (
                    <Shape
                      key={`shape-${index}`}
                      index={index}
                      shape={shape}
                      isSelected={index === selectedId}
                      onSelect={selectShape}
                      onChange={handleChange}
                    />
                  )
                })}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>

      {selectedId === null ? (
        <div className='text-right'>
          <LoaderButton
            label='Save & Continue'
            loading={loading}
            variant='success'
            onClick={handleSave}
          />
        </div>
      ) : (
        <div className='text-right'>
          <LoaderButton
            label='Save'
            loading={loading}
            variant='success'
            onClick={() => selectShape(null)}
          />
        </div>
      )}

      {error && (
        <Alert className='mt-4' variant='danger'>
          {error}
        </Alert>
      )}
    </div>
  )
}

function Shape({ index, shape, isSelected, onSelect, onChange }) {
  const handleSelect = () => {
    onSelect(index)
  }

  const handleChange = newAttrs => {
    onChange(newAttrs, index)
  }

  switch (shape.type) {
    case 'star':
      return (
        <Star
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    case 'rect':
      return (
        <Rectangle
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    case 'circle':
      return (
        <Circle
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    case 'regular_polygon':
      return (
        <RegularPolygon
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    case 'image':
      return (
        <Image
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    case 'text':
      return (
        <Text
          shapeProps={shape}
          isSelected={isSelected}
          onSelect={handleSelect}
          onChange={newAttrs => {
            handleChange(newAttrs)
          }}
        />
      )
    default:
      throw new Error('Invalid overlay item')
  }
}

export default Konva
