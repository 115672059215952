import LargeHeader from 'components/headers/LargeHeader'
import { MediaContext } from 'contexts/MediaContext'
import React, { useContext } from 'react'
import MediaList from './MediaList'

function MediaContent() {
  const { state } = useContext(MediaContext)

  return (
    <React.Fragment>
      {state.data.length > 0 && <LargeHeader>Media</LargeHeader>}
      <MediaList />
    </React.Fragment>
  )
}

export default MediaContent
