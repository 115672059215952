import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import { CreateContext } from 'contexts/CreateContext'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useContext, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import {
  ArrowDownCircle,
  ArrowUpCircle,
  InfoCircleFill,
  TextCenter,
  TextLeft,
  TextRight,
  TypeBold,
  TypeItalic,
} from 'react-bootstrap-icons'
import Form from 'react-bootstrap/Form'
import { ChromePicker } from 'react-color'
import styles from './LayerEdit.module.scss'

const hexToRgb = (hex, opacity) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const longHex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longHex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: opacity,
      }
    : null
}

function LayerEdit({ selectedId, selectShape }) {
  const { state, dispatch } = useContext(CreateContext)

  const handleSubmit = e => {
    e.preventDefault()
    selectShape(null)
  }

  const handleChangeOpacity = value => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'opacity', value: value / 100 },
    })
  }

  const handleChangeCornerRadius = value => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'cornerRadius', value: value },
    })
  }

  const handleChangeText = e => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'text', value: e.target.value },
    })
  }

  const getFontStyle = () => {
    return state.shapes[selectedId].fontStyle ?? ''
  }

  const textHasStyle = style => {
    return getFontStyle().includes(style)
  }

  const handleTextBold = () => {
    const style = getFontStyle()
    const newStyle = textHasStyle('bold') ? style.replace('bold', '') : style + ' bold'

    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'fontStyle', value: newStyle.trim() },
    })
  }

  const handleTextItalic = () => {
    const style = getFontStyle()
    const newStyle = textHasStyle('italic')
      ? style.replace('italic', '')
      : style + ' italic'

    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'fontStyle', value: newStyle.trim() },
    })
  }

  const handleTextAlign = position => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'align', value: position },
    })
  }

  const handleBlurText = e => {
    if (e.target.value === '') {
      dispatch({
        type: 'SET_PROPERTY',
        payload: {
          index: selectedId,
          property: 'text',
          value: 'Some text here',
        },
      })
    }
  }

  const handleChangeFont = e => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'fontFamily', value: e.target.value },
    })
  }

  const handleChangeColor = e => {
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'fill', value: e.hex },
    })
    dispatch({
      type: 'SET_PROPERTY',
      payload: { index: selectedId, property: 'opacity', value: e.rgb.a },
    })
  }

  const handleRemove = () => {
    dispatch({
      type: 'REMOVE_LAYER',
      payload: { index: selectedId },
    })
    selectShape(null)
  }

  const handleShiftLayerForward = () => {
    if (selectedId + 1 !== state.shapes.length) {
      dispatch({
        type: 'SHIFT_LAYER_FORWARD',
        payload: { index: selectedId },
      })
      selectShape(selectedId + 1)
    }
  }

  const handleShiftLayerBack = () => {
    if (selectedId !== 0) {
      dispatch({
        type: 'SHIFT_LAYER_BACK',
        payload: { index: selectedId },
      })
      selectShape(selectedId - 1)
    }
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      {state.shapes[selectedId].hasOwnProperty('opacity') &&
        !state.shapes[selectedId].hasOwnProperty('fill') && (
          <Panel className={`${styles.panel} mb-3`}>
            <Form.Group>
              <Form.Label>Transparency</Form.Label>
              <Slider
                trackStyle={{ background: '#1e2234' }}
                value={state.shapes[selectedId].opacity * 100}
                min={10}
                max={100}
                onChange={handleChangeOpacity}
              />
            </Form.Group>
          </Panel>
        )}

      {state.shapes[selectedId].hasOwnProperty('cornerRadius') && (
        <Panel className={`${styles.panel} mb-3`}>
          <Form.Group>
            <Form.Label>Corner Radius</Form.Label>
            <Slider
              trackStyle={{ background: '#1e2234' }}
              value={state.shapes[selectedId].cornerRadius}
              min={0}
              max={25}
              onChange={handleChangeCornerRadius}
            />
          </Form.Group>
        </Panel>
      )}

      {state.shapes[selectedId].type === 'text' && (
        <Panel className={`${styles.panel} mb-3`}>
          <FontSize selectedId={selectedId} />

          <Form.Group>
            <Form.Label>
              <InfoCircleFill style={{ marginTop: '-4px' }} /> Font
            </Form.Label>

            <Form.Control
              as='select'
              value={state.shapes[selectedId].fontFamily}
              onChange={handleChangeFont}>
              <option value='Arial'>Arial</option>
              <option value='Bungee'>Bungee</option>
              <option value='Josefin Sans'>Josefin Sans</option>
              <option value='Roboto'>Roboto</option>
              <option value='Times New Roman'>Times New Roman</option>
              <option value='Verdana'>Verdana</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <ButtonGroup className='w-100' size='sm'>
              <Button
                className={`${styles.textButton} m-0 px-3`}
                variant='primary'
                active={textHasStyle('bold')}
                onClick={handleTextBold}>
                <TypeBold />
              </Button>
              <Button
                className={`${styles.textButton} m-0 px-3`}
                variant='primary'
                active={textHasStyle('italic')}
                onClick={handleTextItalic}>
                <TypeItalic />
              </Button>
            </ButtonGroup>
          </Form.Group>

          <Form.Group>
            <ButtonGroup className='w-100' size='sm'>
              <Button
                name='left'
                className='m-0 px-3'
                variant='primary'
                active={state.shapes[selectedId].align === 'left'}
                onClick={() => {
                  handleTextAlign('left')
                }}>
                <TextLeft />
              </Button>
              <Button
                className='m-0 px-3'
                variant='primary'
                active={state.shapes[selectedId].align === 'center'}
                onClick={() => {
                  handleTextAlign('center')
                }}>
                <TextCenter />
              </Button>
              <Button
                className='m-0 px-3'
                variant='primary'
                active={state.shapes[selectedId].align === 'right'}
                onClick={() => {
                  handleTextAlign('right')
                }}>
                <TextRight />
              </Button>
            </ButtonGroup>
          </Form.Group>

          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Form.Control
              name='text'
              value={state.shapes[selectedId].text}
              placeholder='Text content'
              as='textarea'
              rows='1'
              onChange={handleChangeText}
              onBlur={handleBlurText}
            />
          </Form.Group>
        </Panel>
      )}

      {state.shapes[selectedId].hasOwnProperty('fill') && (
        <ChromePicker
          className={`${styles.colorPicker} mb-3`}
          color={hexToRgb(
            state.shapes[selectedId].fill,
            state.shapes[selectedId].opacity
          )}
          onChange={handleChangeColor}
        />
      )}

      <Panel className={`${styles.panel} ${styles.layerMoveContainer} mb-3`}>
        <Tooltip label='Move layer forward - appear in front of others'>
          <ArrowUpCircle
            className={`${styles.layerMove} round-icon-button`}
            onClick={handleShiftLayerForward}
          />
        </Tooltip>

        <Tooltip label='Move layer back - appear behind others'>
          <ArrowDownCircle
            className={`${styles.layerMove} round-icon-button`}
            onClick={handleShiftLayerBack}
          />
        </Tooltip>
      </Panel>

      <Button variant='primary' type='submit' block>
        Save
      </Button>

      <Button variant='danger' block onClick={handleRemove}>
        Remove
      </Button>
    </Form>
  )
}

function FontSize({ selectedId }) {
  const { state, dispatch } = useContext(CreateContext)
  const [fontSize, setFontSize] = useState(state.shapes[selectedId].fontSize)
  const minFontSize = 20
  const maxFontSize = 700

  const handleChangeFontSize = e => {
    const value = e.target.value
    setFontSize(value)

    dispatch({
      type: 'SET_PROPERTY',
      payload: {
        index: selectedId,
        property: 'fontSize',
        value: parseInt(Math.min(Math.max(minFontSize, value), maxFontSize)),
      },
    })
  }

  return (
    <Form.Group>
      <Form.Label>Font Size</Form.Label>
      <Form.Control
        isInvalid={fontSize < minFontSize || fontSize > maxFontSize}
        name='font_size'
        value={fontSize}
        placeholder='Font size'
        onChange={handleChangeFontSize}
      />
      {fontSize < minFontSize && (
        <Form.Text className='text-danger'>Minimum size is {minFontSize}.</Form.Text>
      )}
      {fontSize > maxFontSize && (
        <Form.Text className='text-danger'>Maximum size is {maxFontSize}.</Form.Text>
      )}
    </Form.Group>
  )
}

export default LayerEdit
