import LargeGrid from 'components/grid/LargeGrid'
import ListFolders from 'components/list-folders/ListFolders'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import MediaUpload from 'components/media-upload/MediaUpload'
import MediaItem from 'components/media/MediaItem'
import Tooltip from 'components/Tooltip'
import { AuthContext } from 'contexts/AuthContext'
import { CreateContext } from 'contexts/CreateContext'
import { MediaContext } from 'contexts/MediaContext'
import { getSimpleTransformationUrl } from 'helpers/cloudinary'
import { useContext, useRef, useState } from 'react'
import {
  ArrowLeftCircle,
  Circle,
  Fonts,
  House,
  Image,
  Square,
  Star,
  Triangle,
} from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import styles from './LayerAdd.module.scss'

function LayerAdd({ selectedId }) {
  const { user } = useContext(AuthContext)
  const { dispatch } = useContext(CreateContext)
  const {
    state: media,
    loadMore: loadMoreMedia,
    changeFolder: changeMediaFolder,
  } = useContext(MediaContext)
  const [showMedia, setShowMedia] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [changingFolder, setChangingFolder] = useState(false)
  const cloudinaryUploadWidgetRef = useRef()

  const handleRect = () => {
    dispatch({ type: 'ADD_RECT' })
  }

  const handleCircle = () => {
    dispatch({ type: 'ADD_CIRCLE' })
  }

  const handleTriangle = () => {
    dispatch({ type: 'ADD_TRIANGLE' })
  }

  const handleStar = () => {
    dispatch({ type: 'ADD_STAR' })
  }

  const handleText = () => {
    dispatch({ type: 'ADD_TEXT' })
  }

  const handleMedia = () => setShowMedia(true)
  const handleCloseMedia = () => setShowMedia(false)

  const handleLoadMoreMedia = async () => {
    setLoadingMore(true)
    await loadMoreMedia()
    setLoadingMore(false)
  }

  const handleSelectMedia = resource => {
    const url = getSimpleTransformationUrl(
      user.cloud_store.name,
      resource.resource_type,
      resource.public_id,
      Math.max(100, Math.min(1920, resource.width))
    )

    dispatch({ type: 'ADD_IMAGE', payload: { src: url } })
    setShowMedia(false)
  }

  const handleChangeFolder = async folderId => {
    setChangingFolder(true)
    await changeMediaFolder(folderId)
    setChangingFolder(false)
  }

  return (
    <div className={`${styles.grid} mb-4`}>
      <div className={styles.item} onClick={handleRect}>
        <Square />
      </div>
      <div className={styles.item} onClick={handleCircle}>
        <Circle />
      </div>
      <div className={styles.item} onClick={handleTriangle}>
        <Triangle />
      </div>
      <div className={styles.item} onClick={handleStar}>
        <Star />
      </div>
      <div className={styles.item} onClick={handleMedia}>
        <Image />
      </div>
      <div className={styles.item} onClick={handleText}>
        <Fonts />
      </div>

      <Modal
        show={showMedia}
        onHide={handleCloseMedia}
        dialogClassName={styles.mediaModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select an image to overlay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4 ml-1'>
            <MediaUpload
              label='Upload New Media'
              cloudinaryUploadWidgetRef={cloudinaryUploadWidgetRef}
              folderId={media?.meta?.folder?.id}
            />
          </div>

          <div className='mb-3'>
            {media?.meta?.folder?.id !== '' && (
              <>
                <Tooltip label='Media Home'>
                  <Button
                    onClick={async () => handleChangeFolder('')}
                    variant='primary'
                    className='mr-1 px-2 mb-1'>
                    <House className='mt-n1' /> Media Home
                  </Button>
                </Tooltip>

                <Tooltip label='Back one folder'>
                  <Button
                    onClick={() => handleChangeFolder(media.meta.parent_folder.id)}
                    variant='primary'
                    className='mr-3 px-2 mb-1'>
                    <ArrowLeftCircle className='mt-n1' />
                  </Button>
                </Tooltip>
              </>
            )}

            {media?.meta?.child_folders?.length === 0 && media?.data?.length === 0 && (
              <span>No media items found</span>
            )}
          </div>

          {changingFolder ? (
            <Loader />
          ) : (
            <>
              <ListFolders
                folders={media?.meta?.child_folders?.map(x => {
                  x.otherProps = { onClick: () => handleChangeFolder(x.id) }
                  return x
                })}
              />

              <LargeGrid>
                {media.data
                  .filter(x => x.resource_type === 'image')
                  .map(x => (
                    <MediaItem
                      key={x.public_id}
                      resource={x}
                      onSelect={handleSelectMedia}
                    />
                  ))}
              </LargeGrid>

              {media.links.next && (
                <div className='text-center mt-5'>
                  <LoaderButton
                    label='Load More'
                    onClick={handleLoadMoreMedia}
                    loading={loadingMore}
                    variant='success'
                  />
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseMedia}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LayerAdd
