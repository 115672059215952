import { list as listSchedules } from 'api/playlist-schedules'
import { listAll as listPlaylists } from 'api/playlists'
import { create, get, update } from 'api/screens'
import LargeHeader from 'components/headers/LargeHeader'
import LoaderButton from 'components/LoaderButton'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router'

function Edit(props) {
  const { id, folderId } = useParams()
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [playlists, setPlaylists] = useState()
  const [schedules, setSchedules] = useState()
  const [values, setValues] = useState({
    name: '',
    display_item_id: '',
    link_type: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})
  const [url, setUrl] = useState('')
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      const _playlists = await listPlaylists()
      setPlaylists(_playlists.data)

      const _schedules = await listSchedules(1000)
      setSchedules(_schedules.data)

      if (id) {
        const _screen = await get(id)
        setUrl(_screen.data.url)
        setValues({
          name: _screen.data.name,
          link_type: _screen.data.link_type,
          display_item_id: _screen.data.schedule.id
            ? `s|${_screen.data.schedule.id}`
            : `p|${_screen.data.playlist.id}`,
        })
      }

      setInitialLoading(false)
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})
    setLoading(true)

    const body = {
      name: values.name,
      display_item_id: values.display_item_id,
      folder_id: folderId ?? '',
    }

    // only send link type if impersonated by reseller
    if (user.hasOwnProperty('leave_impersonation')) {
      body['link_type'] = values.link_type ?? ''
    }

    const res = id ? await update(id, body) : await create(body)

    if (res.success) {
      props.history.push({ pathname: `/displays/${folderId ?? ''}` })
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setLoading(false)
  }

  if (initialLoading) {
    return null
  }

  return (
    <Container>
      <LargeHeader>{id ? 'Update' : 'Create'} Display</LargeHeader>

      <Form onSubmit={handleSubmit}>
        <div className='mb-4'>
          Setup a display and select the playlist you would like it to display. Once
          created you will be able to retreive a link use with your display.
        </div>

        {url && (
          <div className='mb-4'>
            <strong>
              Display URL:{' '}
              <a href={url} target='_blank' rel='noopener noreferrer'>
                {url}
              </a>
            </strong>
          </div>
        )}

        <Form.Group className='mb-4'>
          <Form.Group>
            <Form.Control
              isInvalid={errors.hasOwnProperty('name')}
              name='name'
              value={values.name}
              placeholder='Display name'
              onChange={handleChange}
            />
            {errors.hasOwnProperty('name') ? (
              <Form.Text className='text-danger'>{errors.name}</Form.Text>
            ) : (
              <Form.Text className='text-muted'>
                This is just for your reference, it is not displayed anywhere.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Select Schedule or Playlist</Form.Label>
            <Form.Control
              as='select'
              placeholder='Schedule or playlist to display'
              name='display_item_id'
              isInvalid={errors.hasOwnProperty('display_item_id')}
              value={values.display_item_id}
              onChange={handleChange}>
              <option value='' disabled>
                Please select
              </option>

              {schedules.length > 0 && (
                <>
                  <option value='' disabled style={{ fontSize: '1.4rem' }}>
                    Schedules
                  </option>
                  {schedules.map(x => (
                    <option key={x.id} value={`s|${x.id}`}>
                      {x.name}
                    </option>
                  ))}
                </>
              )}

              {playlists.length > 0 && (
                <>
                  <option value='' disabled style={{ fontSize: '1.4rem' }}>
                    Playlists
                  </option>
                  {playlists.map(x => (
                    <option key={x.id} value={`p|${x.id}`}>
                      {x.name}
                    </option>
                  ))}
                </>
              )}

              {errors.hasOwnProperty('display_item_id') && (
                <Form.Text className='text-danger'>{errors.display_item_id}</Form.Text>
              )}
            </Form.Control>
          </Form.Group>
        </Form.Group>

        {user.hasOwnProperty('leave_impersonation') && (
          <Form.Group>
            <Form.Label>Link Type</Form.Label>
            <Form.Control
              as='select'
              placeholder='Link Type'
              name='link_type'
              isInvalid={errors.hasOwnProperty('link_type')}
              value={values.link_type}
              onChange={handleChange}>
              <option value={null}>Customer Default</option>
              <option value='download'>Download</option>
              <option value='browser'>Browser</option>
              {errors.hasOwnProperty('link_type') && (
                <Form.Text className='text-danger'>{errors.link_type}</Form.Text>
              )}
            </Form.Control>
          </Form.Group>
        )}

        {errorMessage && (
          <Alert className='mt-4' variant='danger'>
            {errorMessage}
          </Alert>
        )}

        <Button
          variant='secondary'
          className='mt-4 mr-2'
          onClick={() => props.history.goBack()}>
          Cancel
        </Button>

        <LoaderButton
          label='Save'
          className='mt-4 mr-2'
          loading={loading}
          variant='success'
          type='submit'
        />
      </Form>
    </Container>
  )
}

export default Edit
