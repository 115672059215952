import { deletePlaylistSchedule, list } from 'api/playlist-schedules'
import CreateADigitalDisplay from 'components/CreateADigitalDisplay'
import DeleteModal from 'components/DeleteModal'
import LargeHeader from 'components/headers/LargeHeader'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

function Schedules() {
  const [loading, setLoading] = useState(true)
  const [screens, setScreens] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await list()
      setScreens(res)
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deletePlaylistSchedule(id)

    if (success) {
      const res = await list()
      setScreens(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  if (loading) {
    return <Loader />
  }

  if (screens.meta.playlist_count === 0) {
    return (
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <CreateADigitalDisplay />
      </div>
    )
  }

  return (
    <Container>
      <LargeHeader>Playlist Schedules</LargeHeader>

      <Tooltip label='Create a new schedule'>
        <Button className='mb-3' variant='success' as={Link} to='/schedules/create'>
          Create
        </Button>
      </Tooltip>

      <Panel>
        {screens.data.length === 0 ? (
          <div>No schedules created yet.</div>
        ) : (
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {screens.data.map((x, index) => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  <td align='right' style={{ minWidth: '135px' }}>
                    <Tooltip label='Edit Schedule'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/schedules/update/${x.id}`}>
                        <Pencil />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      label={
                        x.in_use ? 'Schedule is in use by display' : 'Delete Schedule'
                      }>
                      <Button
                        disabled={x.in_use}
                        variant='danger'
                        className='px-2'
                        size='sm'
                        onClick={() => handleOpenDeleteDialog(x.id, index)}>
                        <Trash />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Panel>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

export default Schedules
