import { updateMe } from 'api/auth'
import LoaderButton from 'components/LoaderButton'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useState } from 'react'
import { Card, Container } from 'react-bootstrap'

function Terms() {
  const { fetchData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  const handleAccept = async () => {
    setLoading(true)
    await updateMe({ terms_agreed: 1 })
    await fetchData()
  }

  return (
    <Container className='h-100'>
      <div className='row align-items-center justify-content-center'>
        <Card className='mt-4'>
          <Card.Body>
            <div className='d-flex justify-content-center align-items-center'>
              <img src='/logo.png' alt='Logo' />
            </div>
            <p>Please agree to the terms linked below:</p>
            <ul>
              <li>
                <a
                  href='https://visivotech.co.uk/wp-content/uploads/2023/02/VISIVO-TECHNOLOGIES-LTD-EULA-GB-Visivotech-4.pdf'
                  target='_blank'
                  rel='noreferrer'>
                  EULA
                </a>
              </li>
              <li>
                <a
                  href='https://visivotech.co.uk/wp-content/uploads/2023/02/Visivo-Technologies-LTD-Privacy-Policy-17-2-23.pdf'
                  target='_blank'
                  rel='noreferrer'>
                  Privacy Policy
                </a>
              </li>
            </ul>
            <LoaderButton
              label='Accept Terms'
              className='btn-block'
              loading={loading}
              onClick={handleAccept}
            />
          </Card.Body>
        </Card>
      </div>
    </Container>
  )
}

export default Terms
