import config from 'config'
import { getJsonHeaders } from './helpers'

export async function get(playlistId, slideId) {
  const result = await fetch(config.API_URL + `/iapi/slides/${playlistId}/${slideId}`, {
    credentials: 'include',
  })
  return await result.json()
}

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/slides`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  const decoded = await res.json()

  if (res.status === 201 || res.status === 200) {
    return {
      success: true,
      message: 'Successfully created slide',
      data: decoded.data,
    }
  } else {
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  }
}

export async function createMultiple(body) {
  const res = await fetch(config.API_URL + `/iapi/slides/multiple`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  const decoded = await res.json()

  if (res.status === 201 || res.status === 200) {
    return {
      success: true,
      message: 'Successfully created slides',
      data: decoded.data,
    }
  } else {
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/slides/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteSlide(playlistId, slideId) {
  const res = await fetch(config.API_URL + `/iapi/slides/${playlistId}/${slideId}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}
