import LargeHeader from 'components/headers/LargeHeader'
import Panel from 'components/panel/Panel'
import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import styles from './CreateADigitalDisplay.module.scss'

function CreateADigitalDisplay(props) {
  return (
    <Panel className={styles.create}>
      <div className={styles.createContent}>
        <LargeHeader>Create a digital display</LargeHeader>
        <Button as={Link} to='/create' variant='success'>
          Get Started
        </Button>
      </div>
    </Panel>
  )
}

export default CreateADigitalDisplay
