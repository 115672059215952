import { deleteFolder } from 'api/folders'
import LoaderButton from 'components/LoaderButton'
import Tooltip from 'components/Tooltip'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FolderX } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'

function DeleteFolder({ folderId, name, onSuccess }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDelete = async e => {
    e.preventDefault()
    setLoading(true)
    const res = await deleteFolder(folderId)
    if (res) {
      setLoading(false)
      if (onSuccess !== undefined) {
        setLoading(false)
        setOpen(false)
        onSuccess()
      }
    } else {
      setError(res.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Tooltip label='Delete folder'>
        <Button variant='danger' className='mr-1 px-2 mb-1' onClick={handleOpen}>
          <FolderX />
        </Button>
      </Tooltip>

      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Folder {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this item?</p>
          <p>
            Subfolders will be deleted. Any items is within these folders will not be
            deleted, it will be moved to the top level section.
          </p>

          {error && (
            <Alert className='mt-4' variant='danger'>
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <LoaderButton
            loading={loading}
            label='Delete'
            onClick={handleDelete}
            variant='danger'
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

DeleteFolder.propTypes = {
  folderId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
}

export default DeleteFolder
