import config from 'config'
import { getJsonHeaders } from './helpers'

export async function create(body) {
  try {
    fetch(config.API_URL + `/iapi/ui-log`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      credentials: 'include',
      body: JSON.stringify(body),
    })
  } catch (e) {}
}
