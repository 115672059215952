import React from 'react'
import { Text as KonvaText, Transformer } from 'react-konva'

function Text({ shapeProps, isSelected, onSelect, onChange }) {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected, shapeProps.fontFamily])

  return (
    <React.Fragment>
      <KonvaText
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransform={_ => {
          const node = shapeRef.current
          onChange({
            ...shapeProps,
            width: node.width() * node.scaleX(),
            scaleX: 1,
            scaleY: 1,
            rotation: node.rotation(),
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(_, newBox) => {
            newBox.width = Math.max(20, newBox.width)
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Text
