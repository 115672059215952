import { create } from 'api/logging'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    create({ message: btoa(JSON.stringify({ message: error.message, info: info })) })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>Sorry about this, something went wrong.</div>
    }
    return this.props.children
  }
}

export default ErrorBoundary
