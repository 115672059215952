import React from 'react'
import styles from './Grid.module.scss'
import PropTypes from 'prop-types'

function Grid({ maxCols, children }) {
  return (
    <div
      style={{ gridTemplateColumns: `repeat(${maxCols}, 1fr)` }}
      className={styles.grid}>
      {children}
    </div>
  )
}

Grid.propTypes = {
  maxCols: PropTypes.number,
}

Grid.defaultProps = {
  maxCols: 5,
}

export default Grid
