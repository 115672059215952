import config from 'config'
import { getJsonHeaders } from './helpers'

export async function me() {
  try {
    const result = await fetch(config.API_URL + '/iapi/me', {
      headers: await getJsonHeaders(),
      credentials: 'include',
    })

    if (result.status !== 200) {
      return false
    }

    return await result.json()
  } catch (e) {
    return false
  }
}

export async function updateMe(body) {
  const res = await fetch(config.API_URL + `/iapi/me`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function login(email, password) {
  try {
    const res = await fetch(config.API_URL + '/iapi/login', {
      method: 'POST',
      headers: await getJsonHeaders(),
      credentials: 'include',
      body: JSON.stringify({
        email: email,
        password: password,
        remember: '1',
      }),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        two_factor: decoded.two_factor,
      }
    }

    return {
      success: false,
      message: decoded.message,
    }
  } catch (e) {
    return {
      success: false,
      message: 'Login failed',
    }
  }
}

export async function logout() {
  try {
    const res = await fetch(config.API_URL + '/iapi/logout', {
      method: 'POST',
      headers: await getJsonHeaders(),
      credentials: 'include',
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}

export async function resetPasswordEmail(email) {
  try {
    const res = await fetch(config.API_URL + '/iapi/password/email', {
      method: 'POST',
      headers: await getJsonHeaders(),
      credentials: 'include',
      body: JSON.stringify({
        email: email,
      }),
    })

    if (res.status === 200) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send reset password email',
      errors: [['', 'Failed to send reset password email']],
    }
  }
}

export async function resetPassword(token, email, password) {
  try {
    const res = await fetch(config.API_URL + '/iapi/password/reset', {
      method: 'POST',
      headers: await getJsonHeaders(),
      credentials: 'include',
      body: JSON.stringify({
        token: token,
        email: email,
        password: password,
        password_confirmation: password,
      }),
    })

    if (res.status === 200) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send reset password email',
      errors: [['', 'Failed to send reset password email']],
    }
  }
}

export async function enableTwoFactorAuth() {
  const res = await fetch(config.API_URL + '/iapi/two-factor-authentication', {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 200
}

export async function disableTwoFactorAuth() {
  const res = await fetch(config.API_URL + '/iapi/two-factor-authentication', {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 200
}

export async function twoFactorAuthQrCode() {
  const res = await fetch(config.API_URL + '/iapi/two-factor-qr-code', {
    credentials: 'include',
  })

  return await res.json()
}

export async function twoFactorAuthRecoveryCodes() {
  const res = await fetch(config.API_URL + '/iapi/two-factor-recovery-codes', {
    credentials: 'include',
  })

  return await res.json()
}

export async function twoFactorAuthChallenge(body) {
  const res = await fetch(config.API_URL + '/iapi/two-factor-challenge', {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return res.status === 204
}

export async function passwordConfirm(password) {
  const res = await fetch(config.API_URL + '/iapi/confirm-password', {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify({ password: password }),
  })

  return res.status === 201
}
