import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function ScreenLimitHelp(props) {
  return (
    <Tooltip label='The customer will be able to create displays which provide links for their signage. Limit the number of displays that can be created.'>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default ScreenLimitHelp
