import config from 'config'
import { getJsonHeaders } from './helpers'

export async function requestIncreaseLimits(body) {
  const res = await fetch(config.API_URL + `/iapi/request-increase-limits`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}
