import { createMultiple } from 'api/slides'
import Grid from 'components/grid/Grid'
import LargeHeader from 'components/headers/LargeHeader'
import ListFolders from 'components/list-folders/ListFolders'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import MediaUpload from 'components/media-upload/MediaUpload'
import MediaItem from 'components/media/MediaItem'
import Tooltip from 'components/Tooltip'
import UploadMediaToStart from 'components/UploadMediaToStart'
import { CreateContext } from 'contexts/CreateContext'
import { MediaContext } from 'contexts/MediaContext'
import React, { useContext, useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { ArrowLeftCircle, House } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import InfiniteScroll from 'react-infinite-scroll-component'

const maxSelections = 50

function BaseLayer() {
  const { playlistId, state, dispatch, history } = useContext(CreateContext)
  const {
    state: media,
    changeFolder: changeMediaFolder,
    loadMore: loadMoreMedia,
  } = useContext(MediaContext)
  const [loading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [changingFolder, setChangingFolder] = useState(false)
  const [selected, setSelected] = useState([])
  const [creating, setCreating] = useState(false)
  const [error, setError] = useState('')

  const cloudinaryUploadWidgetRef = useRef()

  const handleLoadMoreMedia = async () => {
    setLoadingMore(true)
    await loadMoreMedia()
    setLoadingMore(false)
  }

  const handleSelect = resource => {
    if (selected.length < maxSelections) {
      const newSelected = Array.from(selected)
      const index = newSelected.indexOf(resource)
      index === -1 ? newSelected.push(resource) : newSelected.splice(index, 1)
      setSelected(newSelected)
    }
  }

  const handleSelectBlank = () => {
    dispatch({ type: 'SET_BASE_LAYER', payload: 'blank' })
  }

  const handleContinue = async () => {
    setError('')

    if (selected.length === 1) {
      dispatch({ type: 'SET_BASE_LAYER', payload: selected[0] })
    } else {
      setCreating(true)
      const res = await createMultiple({
        playlist_id: playlistId,
        slides: selected.map(x => ({ base_layer_id: x.id })),
      })
      setCreating(false)

      if (res.success) {
        history.push({ pathname: `/playlists/view/${res.data.id}` })
      } else {
        setError(res.message)
      }
    }
  }

  const handleChangeOrientation = value => {
    dispatch({ type: 'SET_ORIENTATION', payload: value })
  }

  const handleChangeAspectRatio = value => {
    dispatch({ type: 'SET_ASPECT_RATIO', payload: value })
  }

  const handleChangeFolder = async folderId => {
    setChangingFolder(true)
    await changeMediaFolder(folderId)
    setChangingFolder(false)
  }

  return (
    <Container>
      {creating || loading || media.loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {media.data.length === 0 &&
          media.meta.child_folders.length === 0 &&
          media.meta.folder.id === '' ? (
            <div style={{ maxWidth: '400px', margin: 'auto' }}>
              <UploadMediaToStart />
            </div>
          ) : (
            <React.Fragment>
              <LargeHeader>Select Your Base Layers</LargeHeader>

              <div className='mb-2'>First you need to select your base layer(s).</div>

              <div className='mb-2'>
                You can create a slide from scratch by clicking the "Start With a Blank
                Canvas".
              </div>

              <div className='mb-4'>
                Or you can select multiple images/videos from your media below and then
                click continue to quickly create a playlist with multiple items. You can
                then edit and change how these display after.
              </div>

              <br />

              <div className='d-flex'>
                {!playlistId && (
                  <>
                    <Tooltip label='Set the initial orientation of the playlist. This can be changed later.'>
                      <DropdownButton
                        name='orientation'
                        className='mb-3 mr-2'
                        title={`Create playlist in ${state.orientation}`}>
                        <Dropdown.Item
                          onClick={() => handleChangeOrientation('landscape')}>
                          Landscape
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => handleChangeOrientation('portrait')}>
                          Portrait
                        </Dropdown.Item>
                      </DropdownButton>
                    </Tooltip>

                    <Tooltip label="Set the aspect ratio of the playlist. This can't be changed later.">
                      <DropdownButton
                        name='ar'
                        className='mb-3 mr-2'
                        title={`${state.aspect_ratio}`}>
                        <Dropdown.Item onClick={() => handleChangeAspectRatio('16:9')}>
                          16:9
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => handleChangeAspectRatio('32:9')}>
                          32:9
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => handleChangeAspectRatio('9:8')}>
                          9:8
                        </Dropdown.Item>
                      </DropdownButton>
                    </Tooltip>
                  </>
                )}

                <Button className='mb-3 mr-2' onClick={handleSelectBlank}>
                  Start With a Blank Canvas
                </Button>

                <Button
                  className='mb-3 mr-2'
                  onClick={handleContinue}
                  disabled={selected.length <= 0}>
                  {selected.length <= 0
                    ? 'Select some items below'
                    : `Continue with ${selected.length} selected items`}
                </Button>

                <div className='flex-grow-1'></div>

                <div className='mb-3'>
                  <MediaUpload
                    cloudinaryUploadWidgetRef={cloudinaryUploadWidgetRef}
                    folderId={media.meta.folder.id}
                  />
                </div>
              </div>

              {error && (
                <Alert className='mt-4' variant='danger'>
                  {error}
                </Alert>
              )}

              <br />

              {changingFolder ? (
                <div className='mt-5'>
                  <Loader />
                </div>
              ) : (
                <>
                  {media.meta.folder.id !== '' && (
                    <div className='mb-3'>
                      <Tooltip label='Media Home'>
                        <Button
                          onClick={async () => handleChangeFolder('')}
                          variant='primary'
                          className='mr-1 px-2 mb-1'>
                          <House className='mt-n1' /> Media Home
                        </Button>
                      </Tooltip>

                      <Tooltip label='Back one folder'>
                        <Button
                          onClick={() => handleChangeFolder(media.meta.parent_folder.id)}
                          variant='primary'
                          className='mr-3 px-2 mb-1'>
                          <ArrowLeftCircle className='mt-n1' />
                        </Button>
                      </Tooltip>

                      {media.meta.child_folders.length === 0 &&
                        media.data.length === 0 && <span>No media items found</span>}
                    </div>
                  )}

                  <ListFolders
                    folders={media.meta.child_folders.map(x => {
                      x.otherProps = { onClick: () => handleChangeFolder(x.id) }
                      return x
                    })}
                  />

                  <div style={{ minHeight: '80vh' }}>
                    <InfiniteScroll
                      style={{ overflow: 'visible' }}
                      dataLength={media.data.length}
                      next={handleLoadMoreMedia}
                      hasMore={media.links.next}>
                      <Grid>
                        {media.data.map(x => (
                          <MediaItem
                            key={x.public_id}
                            resource={x}
                            orientation={x.width < x.height ? 'portrait' : 'landscape'}
                            selected={selected.includes(x)}
                            onSelect={handleSelect}
                          />
                        ))}
                      </Grid>

                      {media.links.next && (
                        <div className='text-center mt-5'>
                          <LoaderButton
                            label='Load More'
                            onClick={handleLoadMoreMedia}
                            loading={loadingMore}
                            variant='success'
                          />
                        </div>
                      )}
                    </InfiniteScroll>
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Container>
  )
}

export default BaseLayer
