import config from 'config'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import CustomerDashboard from './CustomerDashboard'
import DistributorDashboard from './DistributorDashboard'
import ResellerDashboard from './ResellerDashboard'
import SystemUserDashboard from './SystemUserDashboard'

function Dashboard() {
  const { user } = useContext(AuthContext)

  switch (user.role) {
    case 'system_user':
      return <SystemUserDashboard />
    case 'distributor':
      return <DistributorDashboard />
    case 'reseller':
      return <ResellerDashboard deviceStatusUrl={user.device_status_url} />
    default:
      return <CustomerDashboard />
  }
}

export default Dashboard
