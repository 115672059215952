import { get as getPlaylist } from 'api/playlists'
import { get as getSlide } from 'api/slides'
import React, { useEffect, useReducer } from 'react'

const initialState = {
  type: 'media',
  orientation: 'landscape',
  aspect_ratio: '16:9',
  maxShapes: 10,
  baseLayer: '',
  shapes: [],
  iframe_url: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TYPE':
      return { ...state, type: action.payload }
    case 'SET_ORIENTATION':
      return { ...state, orientation: action.payload }
    case 'SET_ASPECT_RATIO':
      return { ...state, aspect_ratio: action.payload }
    case 'SET_BASE_LAYER':
      return { ...state, baseLayer: action.payload }
    case 'SET_SHAPES':
      return { ...state, shapes: action.payload }
    case 'SET_ALL_SHAPES':
      return { ...state, shapes: action.payload }
    case 'ADD_RECT':
      return { ...state, shapes: addRect(state.shapes) }
    case 'ADD_CIRCLE':
      return { ...state, shapes: addCircle(state.shapes) }
    case 'ADD_TRIANGLE':
      return { ...state, shapes: addTriangle(state.shapes) }
    case 'ADD_STAR':
      return { ...state, shapes: addStar(state.shapes) }
    case 'ADD_IMAGE':
      return { ...state, shapes: addImage(state.shapes, action.payload.src) }
    case 'ADD_TEXT':
      return { ...state, shapes: addText(state.shapes) }
    case 'SET_PROPERTY':
      return {
        ...state,
        shapes: setProperty(
          state.shapes,
          action.payload.index,
          action.payload.property,
          action.payload.value
        ),
      }
    case 'SHIFT_LAYER_FORWARD':
      return { ...state, shapes: shiftLayerForward(state.shapes, action.payload.index) }
    case 'SHIFT_LAYER_BACK':
      return { ...state, shapes: shiftLayerBack(state.shapes, action.payload.index) }
    case 'REMOVE_LAYER':
      return { ...state, shapes: removeLayer(state.shapes, action.payload.index) }
    case 'SET_IFRAME_URL':
      return { ...state, iframe_url: action.payload }
    default:
      throw new Error(`Unhandle reducer action ${action.type}`)
  }
}

const addRect = shapes => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'rect',
    x: 20,
    y: 20,
    rotation: 0,
    scaleX: 2,
    scaleY: 2,
    fill: '#fff',
    opacity: 1,
    cornerRadius: 5,
  })

  return newShapes
}

const addCircle = shapes => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'circle',
    x: 200,
    y: 200,
    rotation: 0,
    scaleX: 2,
    scaleY: 2,
    fill: '#fff',
    opacity: 1,
  })

  return newShapes
}

const addTriangle = shapes => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'regular_polygon',
    x: 200,
    y: 200,
    sides: 3,
    rotation: 0,
    scaleX: 2,
    scaleY: 2,
    fill: '#fff',
    opacity: 1,
    cornerRadius: 5,
  })

  return newShapes
}

const addStar = shapes => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'star',
    x: 100,
    y: 100,
    rotation: 0,
    scaleX: 3,
    scaleY: 3,
    fill: '#fff',
    opacity: 1,
  })

  return newShapes
}

const addImage = (shapes, src) => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'image',
    src: src,
    x: 0,
    y: 0,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
  })

  return newShapes
}

const addText = shapes => {
  const newShapes = Array.from(shapes)
  newShapes.push({
    type: 'text',
    text: 'Some text here',
    fontSize: 100,
    fontStyle: '',
    fontFamily: 'Arial',
    align: 'center',
    x: 200,
    y: 200,
    width: 400,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    fill: '#fff',
    opacity: 1,
  })

  return newShapes
}

const setProperty = (shapes, index, property, value) => {
  const newShapes = Array.from(shapes)
  const shape = newShapes[index]
  shape[property] = value
  newShapes[index] = shape

  return newShapes
}

const removeLayer = (shapes, index) => {
  const newShapes = Array.from(shapes)
  newShapes.splice(index, 1)

  return newShapes
}

const shiftLayerForward = (shapes, index) => {
  const newShapes = Array.from(shapes)

  // if not already at end
  if (index + 1 !== shapes.length) {
    const newIndex = index + 1
    newShapes.splice(newIndex, 0, newShapes.splice(index, 1)[0])
  }

  return newShapes
}

const shiftLayerBack = (shapes, index) => {
  const newShapes = Array.from(shapes)

  // if not already at start
  if (index !== 0) {
    const newIndex = index - 1
    newShapes.splice(newIndex, 0, newShapes.splice(index, 1)[0])
  }

  return newShapes
}

const CreateContext = React.createContext()
const WithCreateContext = ({ playlistId, slideId, history, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const defaultContext = {
    state,
    dispatch,
    playlistId,
    slideId,
    history,
  }

  useEffect(() => {
    const fetchData = async () => {
      if (playlistId && slideId) {
        const res = await getSlide(playlistId, slideId)
        dispatch({ type: 'SET_TYPE', payload: res.data.type })
        dispatch({
          type: 'SET_ORIENTATION',
          payload: res.data.playlist.orientation ?? 'landscape',
        })
        dispatch({
          type: 'SET_ASPECT_RATIO',
          payload: res.data.playlist.aspect_ratio ?? '16:0',
        })
        dispatch({
          type: 'SET_BASE_LAYER',
          payload: res.data.base_layer_media ?? 'blank',
        })
        dispatch({ type: 'SET_ALL_SHAPES', payload: res.data.overlay_data })
        dispatch({ type: 'SET_IFRAME_URL', payload: res.data.iframe_url ?? '' })
      } else if (playlistId) {
        const res = await getPlaylist(playlistId)
        dispatch({
          type: 'SET_ORIENTATION',
          payload: res.data.orientation ?? 'landscape',
        })
        dispatch({
          type: 'SET_ASPECT_RATIO',
          payload: res.data.aspect_ratio ?? '16:9',
        })
      }
    }

    fetchData()
  }, [playlistId, slideId])

  return (
    <CreateContext.Provider value={defaultContext}>{children}</CreateContext.Provider>
  )
}

export { CreateContext }
export default WithCreateContext
