import { list } from 'api/media'
import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

const initialState = {
  loading: true,
  folder_id: '',
  data: [],
  meta: {},
  links: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_FOLDER_ID':
      return { ...state, folder_id: action.payload }
    case 'SET_DATA':
      return { ...state, data: action.payload }
    case 'SET_META':
      return { ...state, meta: action.payload }
    case 'SET_LINKS':
      return { ...state, links: action.payload }
    case 'ADD_TO_DATA':
      return { ...state, data: addToData(state.data, action.payload) }
    case 'REMOVE_FROM_DATA':
      return { ...state, data: removeFromData(state.data, action.payload) }
    case 'REPLACE_IN_DATA':
      return { ...state, data: replaceInData(state.data, action.payload) }
    case 'TOGGLE_FAVOURITE':
      return { ...state, data: toggleFavourite(state.data, action.payload) }
    default:
      throw new Error(`Unhandle reducer action ${action.type}`)
  }
}

const addToData = (data, datum) => {
  const newData = Array.from(data)
  newData.unshift(datum)

  return newData
}

const removeFromData = (data, index) => {
  const newData = Array.from(data)
  newData.splice(index, 1)

  return newData
}

const replaceInData = (data, datum) => {
  const newData = Array.from(data)
  newData.splice(datum.index, 1, datum.item)

  return newData
}

const toggleFavourite = (data, index) => {
  const newData = Array.from(data)
  const newItem = { ...data[index] }
  newItem.favourite = !newItem.favourite
  newData.splice(index, 1, newItem)

  return newData
}

const MediaContext = React.createContext()
const WithMediaContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { folderId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_LOADING', payload: true })
      const res = await list(16, 1, folderId)
      dispatch({ type: 'SET_DATA', payload: res.data })
      dispatch({ type: 'SET_META', payload: res.meta })
      dispatch({ type: 'SET_LINKS', payload: res.links })
      dispatch({ type: 'SET_LOADING', payload: false })
    }

    fetchData()
  }, [folderId])

  const loadMore = async () => {
    const res = await list(
      state.meta.per_page,
      state.meta.current_page + 1,
      state.folder_id ? state.folder_id : folderId
    )
    const newData = state.data.concat(res.data)

    dispatch({ type: 'SET_DATA', payload: newData })
    dispatch({ type: 'SET_META', payload: res.meta })
    dispatch({ type: 'SET_LINKS', payload: res.links })
  }

  const changeFolder = async folderId => {
    dispatch({ type: 'SET_FOLDER_ID', payload: folderId })
    const res = await list(state.meta.per_page, state.meta.current_page, folderId)
    dispatch({ type: 'SET_DATA', payload: res.data })
    dispatch({ type: 'SET_META', payload: res.meta })
    dispatch({ type: 'SET_LINKS', payload: res.links })
  }

  const reloadMeta = async () => {
    const res = await list(
      state.meta.per_page,
      state.meta.current_page,
      state.folder_id ? state.folder_id : folderId
    )
    dispatch({ type: 'SET_META', payload: res.meta })
  }

  const defaultContext = {
    state,
    dispatch,
    loadMore,
    changeFolder,
    reloadMeta,
  }
  return <MediaContext.Provider value={defaultContext}>{children}</MediaContext.Provider>
}

export { MediaContext }
export default WithMediaContext
