import { CloudinaryContext } from 'cloudinary-react'
import { AuthContext } from 'contexts/AuthContext'
import BreadcrumbsContext from 'contexts/BreadcrumbsContext'
import React, { useContext } from 'react'
import Routes from 'Routes'
import CloudinaryUploadWidgetContext from 'contexts/CloudinaryUploadWidgetContext'

function App() {
  const { user } = useContext(AuthContext)

  return (
    <CloudinaryContext className='h-100' cloudName={user ? user.cloud_store.name : ''}>
      <CloudinaryUploadWidgetContext user={user}>
        <BreadcrumbsContext>
          <Routes />
        </BreadcrumbsContext>
      </CloudinaryUploadWidgetContext>
    </CloudinaryContext>
  )
}

export default App
