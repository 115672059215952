import PropTypes from 'prop-types'
import React from 'react'

const rulerLineSizeBig = '10px'
const rulerLineSize = '4px'
const rulerLineWidth = '1px'

function Ruler({ width, height }) {
  return (
    <>
      <RulerTop width={width} />
      <RulerRight height={height} />
      <RulerBottom width={width} />
      <RulerLeft height={height} />
    </>
  )
}

function RulerTop({ width }) {
  return (
    <div
      className='d-flex flex-row align-items-end'
      style={{ position: 'absolute', top: `-${rulerLineSizeBig}` }}>
      {Array.from({ length: width / 5 }).map((x, index) => (
        <MeasureHoriz key={index} big={(index + 1) % 5 === 0} />
      ))}
    </div>
  )
}

function RulerBottom({ width }) {
  return (
    <div
      className='d-flex flex-row align-items-start'
      style={{ position: 'absolute', bottom: `-${rulerLineSizeBig}` }}>
      {Array.from({ length: width / 5 }).map((x, index) => (
        <MeasureHoriz key={index} big={(index + 1) % 5 === 0} />
      ))}
    </div>
  )
}

function RulerLeft({ height }) {
  return (
    <div
      className='d-flex flex-column align-items-end'
      style={{ position: 'absolute', left: `-${rulerLineSizeBig}` }}>
      {Array.from({ length: height / 5 }).map((x, index) => (
        <MeasureVert key={index} big={(index + 1) % 5 === 0} />
      ))}
    </div>
  )
}

function RulerRight({ height }) {
  return (
    <div
      className='d-flex flex-column align-items-start'
      style={{ position: 'absolute', right: `-${rulerLineSizeBig}` }}>
      {Array.from({ length: height / 5 }).map((x, index) => (
        <MeasureVert key={index} big={(index + 1) % 5 === 0} />
      ))}
    </div>
  )
}

function MeasureHoriz({ big }) {
  return (
    <div
      style={{
        width: '5px',
        height: `${big ? rulerLineSizeBig : rulerLineSize}`,
        borderLeft: `${rulerLineWidth} solid black`,
      }}></div>
  )
}

function MeasureVert({ big }) {
  return (
    <div
      style={{
        height: '5px',
        width: `${big ? rulerLineSizeBig : rulerLineSize}`,
        borderTop: `${rulerLineWidth} solid black`,
      }}></div>
  )
}

Ruler.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Ruler
