import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import styles from './PreviewModal.module.scss'

function PreviewModal({ open, onHide, url, orientation, aspectRatio, children }) {
  const getSizerClassName = () => {
    if (orientation === 'landscape') {
      switch (aspectRatio) {
        case '32:9':
          return styles.iframeLandscape329
        case '9:8':
          return styles.iframeLandscape98
        default:
          return styles.iframeLandscape
      }
    } else {
      switch (aspectRatio) {
        case '32:9':
          return styles.iframePortrait329
        case '9:8':
          return styles.iframeLandscape89
        default:
          return styles.iframePortrait
      }
    }
  }

  return (
    <Modal
      size='lg'
      show={open}
      onHide={onHide}
      aria-labelledby='example-modal-sizes-title-lg'>
      <Modal.Header closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <p>
          Note: if this playlist is set to display in portrait, iFrames will not display
          at full width due to the size of the preview window. When viewed in full screen,
          they will display as expected.
        </p>

        {children}

        <iframe
          className={getSizerClassName()}
          title='Preview'
          src={url}
          frameBorder='0'></iframe>
      </Modal.Body>
    </Modal>
  )
}

PreviewModal.propTypes = {
  open: PropTypes.bool,
  onHide: PropTypes.func,
  playlistId: PropTypes.string,
  orientation: PropTypes.string,
  aspectRatio: PropTypes.string,
}

PreviewModal.defaultProps = {
  open: false,
  playlistId: '',
  orientation: 'landscape',
  aspectRatio: '16:9',
}

export default PreviewModal
