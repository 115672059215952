import Panel from 'components/panel/Panel'
import React from 'react'
import { Box, Building, People } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import ActionRow from './ActionRow'
import styles from './Dashboard.module.scss'

function DistributorDashboard() {
  return (
    <Container>
      <div>
        <Panel>
          <ActionRow
            title='Manage Resellers'
            icon={<Building className={styles.actionIcon} />}
            color='#bdeeba'
            to='/resellers'>
            Manage and create resellers to use the system.
          </ActionRow>
          <ActionRow
            title='Manage Packages'
            icon={<Box className={styles.actionIcon} />}
            color='#fffbc7'
            to='/packages'>
            Manage and create billing packages.
          </ActionRow>
          <ActionRow
            title='Manage Admin Users'
            icon={<People className={styles.actionIcon} />}
            color='#f4b9a2'
            to='/users'>
            Setup additional admin user to manage Visivo Connect.
          </ActionRow>
        </Panel>
      </div>
    </Container>
  )
}

export default DistributorDashboard
