import { list as listDistributors } from 'api/distributors'
import { create, get, update } from 'api/packages'
import { list as listResellers } from 'api/resellers'
import LargeHeader from 'components/headers/LargeHeader'
import LoaderButton from 'components/LoaderButton'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router'
import DistributorHelp from './help/DistributorHelp'
import MediaLimitHelp from './help/MediaLimitHelp'
import MonthlySellPriceHelp from './help/MonthlySellPriceHelp'
import ResellerHelp from './help/ResellerHelp'
import ScreenLimitHelp from './help/ScreenLimitHelp'
import UserLimitHelp from './help/UserLimitHelp'

function Edit(props) {
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [distributors, setDistributors] = useState([])
  const [resellers, setResellers] = useState([])
  const [values, setValues] = useState({
    name: '',
    user_limit: 10,
    screen_limit: 10,
    media_limit_mb: 100,
    monthly_sell_pence: '',
    distributor_ids: [],
    reseller_ids: [],
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (user.role === 'system_user') {
        listDistributors(0, 10000).then(x => setDistributors(x.data))
      }

      if (user.role === 'distributor') {
        listResellers(0, 10000).then(x => setResellers(x.data))
      }

      if (id) {
        const _package = await get(id)
        setValues({
          name: _package.data.name,
          user_limit: _package.data.user_limit,
          screen_limit: _package.data.screen_limit,
          media_limit_mb: _package.data.media_limit_mb,
          monthly_sell_pence: _package.data.monthly_sell_pence / 100,
          distributor_ids: _package.data.distributor_ids ?? [],
          reseller_ids: _package.data.reseller_ids ?? [],
        })
      }

      setInitialLoading(false)
    }

    fetchData()
  }, [id, user.role])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})
    setLoading(true)

    const body = {
      name: values.name,
      user_limit: values.user_limit,
      screen_limit: values.screen_limit,
      media_limit_mb: values.media_limit_mb,
      monthly_sell_pence: values.monthly_sell_pence
        ? values.monthly_sell_pence * 100
        : '',
    }

    if (user.role === 'system_user') {
      body.distributor_ids = values.distributor_ids
    }

    if (user.role === 'distributor') {
      body.reseller_ids = values.reseller_ids
    }

    const res = id ? await update(id, body) : await create(body)

    if (res.success) {
      props.history.goBack()
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setLoading(false)
  }

  if (initialLoading) {
    return null
  }

  return (
    <Container>
      <LargeHeader>{id ? 'Update' : 'Create'} Package</LargeHeader>

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <UserLimitHelp /> <Form.Label>Package Name</Form.Label>
          <Form.Control
            isInvalid={errors.hasOwnProperty('name')}
            name='name'
            placeholder='Enter the package name'
            value={values.name}
            onChange={handleChange}
            disabled={user.role !== 'system_user'}
          />
          {errors.hasOwnProperty('name') && (
            <Form.Text className='text-danger'>{errors.name}</Form.Text>
          )}
        </Form.Group>

        <Form.Group>
          <UserLimitHelp /> <Form.Label>User Limit</Form.Label>
          <Form.Control
            isInvalid={errors.hasOwnProperty('user_limit')}
            name='user_limit'
            type='number'
            placeholder='Enter the user limit for this customer'
            value={values.user_limit}
            onChange={handleChange}
            disabled={user.role !== 'system_user'}
          />
          {errors.hasOwnProperty('user_limit') && (
            <Form.Text className='text-danger'>{errors.user_limit}</Form.Text>
          )}
        </Form.Group>

        <Form.Group>
          <ScreenLimitHelp /> <Form.Label>Display Limit</Form.Label>
          <Form.Control
            isInvalid={errors.hasOwnProperty('screen_limit')}
            name='screen_limit'
            type='number'
            placeholder='Enter the display limit for this customer'
            value={values.screen_limit}
            onChange={handleChange}
            disabled={user.role !== 'system_user'}
          />
          {errors.hasOwnProperty('screen_limit') && (
            <Form.Text className='text-danger'>{errors.screen_limit}</Form.Text>
          )}
        </Form.Group>

        <Form.Group>
          <MediaLimitHelp /> <Form.Label>Media Limit (MB)</Form.Label>
          <Form.Control
            isInvalid={errors.hasOwnProperty('media_limit_mb')}
            name='media_limit_mb'
            type='number'
            placeholder='Enter the media limit (MB) for this customer'
            value={values.media_limit_mb}
            onChange={handleChange}
            disabled={user.role !== 'system_user'}
          />
          {errors.hasOwnProperty('media_limit_mb') && (
            <Form.Text className='text-danger'>{errors.media_limit_mb}</Form.Text>
          )}
        </Form.Group>

        <Form.Group>
          <MonthlySellPriceHelp /> <Form.Label>Monthly Sell Price (£)</Form.Label>
          <Form.Control
            isInvalid={errors.hasOwnProperty('monthly_sell_pence')}
            name='monthly_sell_pence'
            type='number'
            placeholder='Enter the monthly sell price'
            value={values.monthly_sell_pence}
            onChange={handleChange}
          />
          {errors.hasOwnProperty('monthly_sell_pence') && (
            <Form.Text className='text-danger'>{errors.monthly_sell_pence}</Form.Text>
          )}
        </Form.Group>

        {user.role === 'system_user' && (
          <Form.Group style={{ marginTop: '20px' }}>
            <DistributorHelp /> <Form.Label>Distributors</Form.Label>
            <Typeahead
              id='distributor_ids'
              labelKey='name'
              multiple
              onChange={value => setValues({ ...values, distributor_ids: value })}
              options={distributors}
              placeholder='All distributors can use this package'
              selected={values.distributor_ids}
            />
            {errors.hasOwnProperty('distributor_ids') && (
              <Form.Text className='text-danger'>{errors.distributor_ids}</Form.Text>
            )}
          </Form.Group>
        )}

        {user.role === 'distributor' && (
          <Form.Group style={{ marginTop: '20px' }}>
            <ResellerHelp /> <Form.Label>Resellers</Form.Label>
            <Typeahead
              id='reseller_ids'
              labelKey='name'
              multiple
              onChange={value => setValues({ ...values, reseller_ids: value })}
              options={resellers}
              placeholder='All resellers can use this package'
              selected={values.reseller_ids}
            />
            {errors.hasOwnProperty('reseller_ids') && (
              <Form.Text className='text-danger'>{errors.reseller_ids}</Form.Text>
            )}
          </Form.Group>
        )}

        {errorMessage && (
          <Alert className='mt-4' variant='danger'>
            {errorMessage}
          </Alert>
        )}

        <Button
          variant='secondary'
          className='mt-4 mr-2'
          onClick={() => props.history.goBack()}>
          Cancel
        </Button>

        <LoaderButton
          label='Save'
          className='mt-4'
          loading={loading}
          variant='success'
          type='submit'
        />
      </Form>
    </Container>
  )
}

export default Edit
