import LargeHeader from 'components/headers/LargeHeader'
import MediaUpload from 'components/media-upload/MediaUpload'
import Panel from 'components/panel/Panel'
import React, { useRef } from 'react'
import styles from './CreateADigitalDisplay.module.scss'

function UploadMediaToStart() {
  const cloudinaryUploadWidgetRef = useRef()

  return (
    <Panel className={styles.create}>
      <div className={styles.createContent}>
        <LargeHeader>Upload media to get started</LargeHeader>
        <MediaUpload cloudinaryUploadWidgetRef={cloudinaryUploadWidgetRef} />
      </div>
    </Panel>
  )
}

export default UploadMediaToStart
