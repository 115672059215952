import PropTypes from 'prop-types'
import React from 'react'
import styles from './Logo.module.scss'

function Logo({ large }) {
  return (
    <img className={styles.logo} src={large ? '/logo-2.png' : 'logo.png'} alt='Logo' />
  )
}

Logo.propTypes = {
  large: PropTypes.bool,
}

Logo.defaultProps = {
  large: false,
}

export default Logo
