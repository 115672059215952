import config from 'config'
import { getJsonHeaders } from './helpers'

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/folders`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 201,
    data: await res.json(),
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/folders/${id}`, {
    method: 'put',
    headers: await getJsonHeaders(),
    credentials: 'include',
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteFolder(id) {
  const res = await fetch(config.API_URL + `/iapi/folders/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    credentials: 'include',
  })

  return res.status === 204
}
