import { requestNewPackage } from 'api/packages'
import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

function RequestNewPackage({ used, limit, exceededLimit, children }) {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)
  const [error, setError] = useState()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleChange = e => {
    setMessage(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setError('')
    setLoading(true)

    const res = await requestNewPackage({ message: message })

    if (res.success) {
      setSent(true)
    } else {
      setError('There was an error sending the request')
    }

    setLoading(false)
  }

  return (
    <React.Fragment>
      <Button size='sm' variant='primary' onClick={handleShow}>
        Request New Package
      </Button>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request New Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sent ? (
            <Alert variant='success'>Your request has been sent.</Alert>
          ) : (
            <React.Fragment>
              <div className='mb-4'>
                If you a new package please enter different data storage or display
                capacity please enter details below and one of our team will be in touch
                shortly.
              </div>

              <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-4'>
                  <Form.Group>
                    <Form.Control
                      name='message'
                      as='textarea'
                      rows={4}
                      value={message}
                      placeholder='What type of package do you require?'
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Group>

                {error && <Alert variant='danger'>{error}</Alert>}
              </Form>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {!sent && (
            <Button disabled={loading} variant='success' onClick={handleSubmit}>
              Submit Request
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default RequestNewPackage
