function Toolbar({ children }) {
  return (
    <div className='px-3 mb-3'>
      <div className='d-flex flex-wrap'>{children}</div>
    </div>
  )
}

function ToolbarGroup({ children }) {
  return <div className='d-flex mr-3'>{children}</div>
}

export { ToolbarGroup }
export default Toolbar
