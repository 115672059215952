import { create } from 'api/slides'
import LargeHeader from 'components/headers/LargeHeader'
import LoaderButton from 'components/LoaderButton'
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext'
import { CreateContext } from 'contexts/CreateContext'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'

function IframeContent() {
  const { state, playlistId, slideId, history } = useContext(CreateContext)
  const { setBreadcrumbs } = useContext(BreadcrumbsContext)

  const [url, setUrl] = useState('')
  const [creating, setCreating] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const breadcrumbs = [
      {
        label: playlistId ? 'Existing Playlist' : 'New Playlist',
        href: playlistId ? `/playlists/view/${playlistId}` : '#',
        active: playlistId ? false : true,
      },
      { label: slideId ? 'Existing Slide' : 'New Slide', href: '#', active: true },
      { label: 'Embedded Website', href: '#', active: true },
    ]

    setBreadcrumbs(breadcrumbs)
    setUrl(state.iframe_url)

    return () => {
      setBreadcrumbs([])
    }
  }, [setBreadcrumbs, playlistId, slideId, state.iframe_url])

  const handleCreate = async () => {
    setCreating(true)
    const res = await create({
      slide_id: slideId ? slideId : '',
      playlist_id: playlistId ? playlistId : '',
      type: 'iframe',
      overlay_data: { url: url },
    })

    if (res.success) {
      history.push({ pathname: `/playlists/${res.data.playlist.id}` })
    } else {
      setCreating(false)
      setError(res.message)
    }
  }

  return (
    <Container style={{ height: '100vh' }}>
      <LargeHeader>Enter Website URL Below</LargeHeader>
      <Form.Group>
        <Form.Control
          placeholder='URL of website to embed'
          name='playlist_id'
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </Form.Group>

      <LoaderButton
        className='mb-4'
        label='Save'
        onClick={handleCreate}
        loading={creating}
        variant='success'
      />

      {error && (
        <Alert className='mt-4' variant='danger'>
          {error}
        </Alert>
      )}

      <div className='mb-2'>
        As you enter the URL you will see a preview below. Some websites prevent external
        websites from being able to embed their site, you may see a refused to connect, or
        similar, message in these scenarios.
      </div>

      <div className='mb-3'>
        You should enter the full URL including https:// at the start.
      </div>

      <iframe
        frameBorder='0'
        src={url}
        style={
          state.orientation === 'landscape'
            ? { width: '100%', height: '500px' }
            : { width: '500px', height: '100%' }
        }
        title='Iframe example'></iframe>
    </Container>
  )
}

export default IframeContent
