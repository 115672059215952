import { generateSignature as generateSignatureRequest } from 'api/media'
import React, { useRef } from 'react'

const CloudinaryUploadWidgetContext = React.createContext()
const WithCloudinaryUploadWidget = ({ user, children }) => {
  const cloudinaryUploadWidgetRef = useRef()

  const generateSignature = (callback, params) => {
    generateSignatureRequest({ params: params }).then(res => callback(res.signature))
  }

  const openUploadWidget = callback => {
    cloudinaryUploadWidgetRef.current = window.cloudinary.createUploadWidget(
      {
        cloudName: user.cloud_store.name,
        uploadPreset: 'screens',
        apiKey: user.cloud_store.key,
        folder: user.owner.id,
        useFilename: true,
        uploadSignature: generateSignature,
        sources: ['local', 'camera', 'url'],
        showPoweredBy: false,
        // maxImageFileSize: 8000000,
        //  maxVideoFileSize: 50000000,
        // maxFiles: 15,
        clientAllowedFormats: ['png', 'jpeg', 'jpg', 'mp4', 'webm', 'mov', 'pdf'],
        styles: {
          palette: {
            window: '#151722',
            windowBorder: '#90A0B3',
            tabIcon: '#FFFFFF',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#1E2234',
            action: '#63D75C',
            inactiveTabIcon: '#A3A4A5',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#63D75C',
            sourceBg: '#EEF2F9',
          },
        },
      },
      callback
    )

    cloudinaryUploadWidgetRef.current.open()
  }

  const defaultContext = { openUploadWidget }
  return (
    <CloudinaryUploadWidgetContext.Provider value={defaultContext}>
      {children}
    </CloudinaryUploadWidgetContext.Provider>
  )
}

export { CloudinaryUploadWidgetContext }
export default WithCloudinaryUploadWidget
