import { create, get, update } from 'api/playlist-schedules'
import { listAll } from 'api/playlists'
import LargeHeader from 'components/headers/LargeHeader'
import LoaderButton from 'components/LoaderButton'
import Tooltip from 'components/Tooltip'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router'
import { Typeahead } from 'react-bootstrap-typeahead'

function Edit(props) {
  const { id } = useParams()
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [playlists, setPlaylists] = useState()
  const [values, setValues] = useState({
    name: '',
    type: 'weekly',
    playlists: [],
  })

  const [typeSelected, setTypeSelected] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const _playlists = await listAll()
      setPlaylists(_playlists.data)

      if (id) {
        const _schedule = await get(id)
        setValues({
          name: _schedule.data.name,
          type: _schedule.data.type,
          playlists: _schedule.data.playlists,
        })
        setTypeSelected(_schedule.data.type ? true : false)
      }

      setInitialLoading(false)
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    setErrors({})
    setErrorMessage('')
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleTypeSelected = () => {
    setTypeSelected(values.type)
  }

  const handlePlaylistChange = (index, event) => {
    setErrors({})
    setErrorMessage('')
    const { name, value } = event.target
    const newPlaylists = [...values.playlists]
    newPlaylists[index][name] = value
    setValues({ ...values, playlists: newPlaylists })
  }

  const handlePlaylistRemove = index => {
    setErrors({})
    setErrorMessage('')
    const newPlaylists = [...values.playlists]
    newPlaylists.splice(index, 1)
    setValues({ ...values, playlists: newPlaylists })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})
    setLoading(true)

    const body = {
      name: values.name,
      type: values.type,
      playlists: values.playlists,
    }

    const res = id ? await update(id, body) : await create(body)

    if (res.success) {
      props.history.push({ pathname: '/schedules' })
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setLoading(false)
  }

  if (initialLoading) {
    return null
  }

  return (
    <Container>
      <LargeHeader>{id ? 'Update' : 'Create'} Playlist Schedule</LargeHeader>

      {!typeSelected ? (
        <>
          <p>What type of schedule would you like to set up?</p>
          <ul>
            <li>
              Weekly Schedule: A weekly schedule allows you to automatically change the
              displayed playlist based on the day and time.
            </li>
            <li>
              Calendar Schedule: A calendar schedule allows you to automatically change
              the displayed playlist based date. Once you have created a schedule, head
              over to the displays section and assign the schedule to a display.
            </li>
          </ul>
          <Form.Group>
            <Form.Control
              as='select'
              isInvalid={errors.hasOwnProperty('type')}
              name='type'
              value={values.type}
              onChange={handleChange}>
              <option value='weekly'>Weekly</option>
              <option value='calendar'>Calendar</option>
            </Form.Control>
          </Form.Group>
          <LoaderButton
            label='Next'
            className='mt-4 mr-2'
            onClick={handleTypeSelected}
            variant='success'
          />
        </>
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className='mb-2'>
            A schedule allows you to automatically change the displayed playlist based on
            the day and time.
          </div>

          <div className='mb-4'>
            Once you have created a schedule, head over to the displays section and assign
            the schedule to a display.
          </div>

          <Form.Group className='mb-4'>
            <Form.Group>
              <Form.Control
                isInvalid={errors.hasOwnProperty('name')}
                name='name'
                value={values.name}
                placeholder='Schedule name'
                onChange={handleChange}
              />
              {errors.hasOwnProperty('name') ? (
                <Form.Text className='text-danger'>{errors.name}</Form.Text>
              ) : (
                <Form.Text className='text-muted'>
                  This is just for your reference, it is not displayed anywhere.
                </Form.Text>
              )}
            </Form.Group>

            {values.playlists.map((x, i) => (
              <PlaylistRow
                key={i}
                index={i}
                item={x}
                onChange={handlePlaylistChange}
                onRemove={handlePlaylistRemove}
                playlists={playlists}
                errors={errors}
                scheduleType={values.type}
              />
            ))}
          </Form.Group>

          <div>
            <Button
              variant='primary'
              className='mt-4 mr-2'
              onClick={() => {
                const newPlaylists = [...values.playlists]
                newPlaylists.push({ playlist_id: '', time: '', day: [] })
                setValues({ ...values, playlists: newPlaylists })
              }}>
              Add Playlist to Schedule
            </Button>
          </div>

          {errorMessage && (
            <Alert className='mt-4' variant='danger'>
              {errorMessage}
            </Alert>
          )}

          <Button
            variant='secondary'
            className='mt-4 mr-2'
            onClick={() => props.history.goBack()}>
            Cancel
          </Button>

          <LoaderButton
            label='Save'
            className='mt-4 mr-2'
            loading={loading}
            variant='success'
            type='submit'
          />
        </Form>
      )}
    </Container>
  )
}

function PlaylistRow({
  index,
  item,
  onChange,
  onRemove,
  playlists,
  errors,
  scheduleType,
}) {
  const handleChange = e => onChange(index, e)

  const handleChangeDay = value => {
    onChange(index, { target: { name: 'day', value: value } })
  }

  const handleChangeDate = event => {
    onChange(index, {
      target: {
        name: 'day',
        value: { value: event.target.value, name: event.target.value },
      },
    })
  }

  return (
    <Row>
      <Col xs={12} md={5}>
        <Form.Group>
          <Form.Label>Select Playlist</Form.Label>
          <Form.Control
            as='select'
            placeholder='Playlist to display'
            name='playlist_id'
            isInvalid={errors.hasOwnProperty(`playlists.${index}.playlist_id`)}
            value={item.playlist_id}
            onChange={handleChange}>
            <option value='' disabled>
              Please select
            </option>
            {playlists.map(x => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
          </Form.Control>
          {errors.hasOwnProperty(`playlists.${index}.playlist_id`) && (
            <Form.Text className='text-danger'>
              {errors[`playlists.${index}.playlist_id`]}
            </Form.Text>
          )}
        </Form.Group>
      </Col>

      {scheduleType === 'weekly' && (
        <Col xs={5} md={3}>
          <Form.Group>
            <Form.Label>Day(s)</Form.Label>
            <Typeahead
              id='day'
              labelKey='name'
              multiple
              onChange={handleChangeDay}
              options={[
                { value: '1', name: 'Monday' },
                { value: '2', name: 'Tuesday' },
                { value: '3', name: 'Wednesday' },
                { value: '4', name: 'Thursday' },
                { value: '5', name: 'Friday' },
                { value: '6', name: 'Saturday' },
                { value: '7', name: 'Sunday' },
              ]}
              placeholder='Day(s)'
              selected={item.day}
            />
            {errors.hasOwnProperty(`playlists.${index}.day`) && (
              <Form.Text className='text-danger'>
                {errors[`playlists.${index}.day`]}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      )}

      {scheduleType === 'calendar' && (
        <Col xs={5} md={3}>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              value={item.day.value}
              type='date'
            />
            {errors.hasOwnProperty(`playlists.${index}.day`) && (
              <Form.Text className='text-danger'>
                {errors[`playlists.${index}.day`]}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      )}

      <Col xs={5} md={3}>
        <Form.Group>
          <Form.Label>Time (eg 13:00)</Form.Label>
          <Form.Control
            type='time'
            className='w-100'
            placeholder='Time - 13:00'
            name='time'
            isInvalid={errors.hasOwnProperty(`playlists.${index}.time`)}
            value={item.time}
            onChange={handleChange}
          />
          {errors.hasOwnProperty(`playlists.${index}.time`) && (
            <Form.Text className='text-danger'>
              {errors[`playlists.${index}.time`]}
            </Form.Text>
          )}
        </Form.Group>
      </Col>

      <Col xs={2} md={1}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <Tooltip label='Remove from Schedule'>
            <Button
              variant='danger'
              className='px-2'
              size='sm'
              onClick={() => onRemove(index)}>
              <Trash />
            </Button>
          </Tooltip>
        </div>
      </Col>
    </Row>
  )
}

export default Edit
