import { deleteUser, list } from 'api/users'
import DeleteModal from 'components/DeleteModal'
import LargeHeader from 'components/headers/LargeHeader'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import UsageBanner from 'components/UsageBanner'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { DoorOpen, Pencil, Trash } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

function Users(props) {
  const { ownerType, ownerId } = useParams()
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await list(15, 1, ownerType, ownerId)
      setUsers(res)
      setLoading(false)
    }

    fetchData()
  }, [ownerType, ownerId])

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteUser(id)

    if (success) {
      const res = await list(15, 1, ownerType, ownerId)
      setUsers(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <LargeHeader>{ownerId && `${users.meta.owner.name} `}Users</LargeHeader>
      <Tooltip label='Create a new user'>
        <Button
          className='mb-3'
          variant='success'
          as={Link}
          to={`/users/create${ownerId ? `/${ownerType}/${ownerId}` : ''}`}>
          Create
        </Button>
      </Tooltip>

      <UsageBanner
        used={users.meta.usage.used}
        limit={users.meta.usage.limit}
        exceededLimit={users.meta.usage.used >= users.meta.usage.limit}>
        {' '}
        users created
      </UsageBanner>

      <Panel>
        {users.data.length === 0 ? (
          <div>No users created yet.</div>
        ) : (
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.data.map((x, index) => (
                <tr key={x.id}>
                  <td>
                    {x.first_name} {x.last_name}
                  </td>
                  <td>{x.email}</td>
                  <td align='right' style={{ minWidth: '135px' }}>
                    {x.impersonate_link && (
                      <Tooltip label='Impersonate User'>
                        <Button
                          className='mr-1 px-2'
                          variant='primary'
                          size='sm'
                          as='a'
                          href={x.impersonate_link}>
                          <DoorOpen />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip label='Edit User'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/users/update/${x.id}`}>
                        <Pencil />
                      </Button>
                    </Tooltip>
                    {x.id === user.id ? (
                      <Tooltip label='You are currently logged in as this user'>
                        <Button variant='danger' className='px-2' size='sm' disabled>
                          <Trash />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip label='Delete User'>
                        <Button
                          variant='danger'
                          className='px-2'
                          size='sm'
                          onClick={() => handleOpenDeleteDialog(x.id, index)}>
                          <Trash />
                        </Button>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Panel>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

export default Users
