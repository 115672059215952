import LoaderButton from 'components/LoaderButton'
import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

function DuplicateModal({ show, onHide, duplicating, onDuplicate, errorMessage }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to duplicate this playlist?</p>
        <p>
          The new playlist will copy all information and slides, it will be name the same
          but with "Copy of" at the start.
        </p>

        {errorMessage && (
          <Alert variant='danger'>There was an error duplicating the playlist.</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={duplicating} variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <LoaderButton
          loading={duplicating}
          label='Duplicate'
          onClick={onDuplicate}
          variant='success'
        />
      </Modal.Footer>
    </Modal>
  )
}

DuplicateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  duplicating: PropTypes.bool,
  onDuplicate: PropTypes.func,
  errorMessage: PropTypes.string,
}

DuplicateModal.defaultProps = {
  show: false,
  duplicating: false,
  errorMessage: '',
}

export default DuplicateModal
