import React, { useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'

const BreadcrumbsContext = React.createContext()
const WithBreadcrumbs = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumb as='div'>
        {breadcrumbs.map(x => (
          <Breadcrumb.Item
            key={x.label}
            linkAs={Link}
            linkProps={{ to: x.href }}
            active={x.active}>
            {x.label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  }

  const defaultContext = { setBreadcrumbs, renderBreadcrumbs }
  return (
    <BreadcrumbsContext.Provider value={defaultContext}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export { BreadcrumbsContext }
export default WithBreadcrumbs
