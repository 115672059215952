import { deleteReseller, list } from 'api/resellers'
import DeleteModal from 'components/DeleteModal'
import LargeHeader from 'components/headers/LargeHeader'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil, People, Trash } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

function Resellers(props) {
  const [loading, setLoading] = useState(true)
  const [resellers, setResellers] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await list()
      setResellers(res)
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteReseller(id)

    if (success) {
      const res = await list()
      setResellers(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <LargeHeader>Resellers</LargeHeader>
      <Tooltip label='Create a new reseller'>
        <Button className='mb-3' variant='success' as={Link} to='/resellers/create'>
          Create
        </Button>
      </Tooltip>

      <Panel>
        {resellers.data.length === 0 ? (
          <div>No resellers created yet.</div>
        ) : (
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {resellers.data.map((x, index) => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  <td align='right' style={{ minWidth: '90px' }}>
                    <Tooltip label={`Manage reseller's users`}>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/users/reseller/${x.id}`}>
                        <People />
                      </Button>
                    </Tooltip>
                    <Tooltip label='Edit Reseller'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/resellers/update/${x.id}`}>
                        <Pencil />
                      </Button>
                    </Tooltip>
                    <Tooltip label='Delete Reseller'>
                      <Button
                        variant='danger'
                        className='px-2'
                        size='sm'
                        onClick={() => handleOpenDeleteDialog(x.id, index)}>
                        <Trash />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Panel>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </Container>
  )
}

export default Resellers
