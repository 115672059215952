import React from 'react'
import Alert from 'react-bootstrap/Alert'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import AuthContext from './contexts/AuthContext'
import './index.scss'
import * as serviceWorker from './serviceWorker'

const bsAlertToast = ({ appearance, children }) => (
  <Alert className='px-5' variant={appearance}>
    {children}
  </Alert>
)

ReactDOM.render(
  <Router>
    <AuthContext>
      <ErrorBoundary>
        <ToastProvider
          components={{ Toast: bsAlertToast }}
          autoDismiss={true}
          placement='bottom-center'>
          <App />
        </ToastProvider>
      </ErrorBoundary>
    </AuthContext>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
