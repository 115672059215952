import Konva from 'components/create/Konva'
import LargeHeader from 'components/headers/LargeHeader'
import { CreateContext } from 'contexts/CreateContext'
import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import LayerAdd from './LayerAdd'
import LayerEdit from './LayerEdit'
import styles from './Overlay.module.scss'

function Overlay() {
  const { state, dispatch } = useContext(CreateContext)
  const [selectedId, selectShape] = useState(null)

  return (
    <div>
      <LargeHeader>Create Your Overlay</LargeHeader>

      <div className='mb-4'>
        Create an overlay by selecting shapes, images and text to display on top of the
        base layer.
      </div>

      <div className={styles.overlayContainer}>
        <div className='mr-3 mb-4'>
          {selectedId !== null ? (
            <LayerEdit selectedId={selectedId} selectShape={selectShape} />
          ) : (
            <div>
              <div className='text-muted mb-3'>
                Click an options below to add it as an overlay.
              </div>

              {state.shapes.length > state.maxShapes ? (
                <div className='mb-3'>Maximum number of layers reached</div>
              ) : (
                <LayerAdd />
              )}

              <div className='text-muted mb-3'>
                Edit an existing layer by selecting it below or by click on the preview.
              </div>

              <Button
                variant='primary'
                size='sm'
                className='px-1'
                block
                onClick={() => {
                  dispatch({ type: 'SET_BASE_LAYER', payload: '' })
                }}>
                Change Base Layer
              </Button>

              <div>
                {state.shapes.map((x, index) => (
                  <Button
                    key={`layer-${index}`}
                    size='sm'
                    block
                    onClick={() => selectShape(index)}
                    style={{ marginTop: '1px' }}>
                    Overlay {index + 1}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
        <Konva selectedId={selectedId} selectShape={selectShape} />
      </div>
    </div>
  )
}

export default Overlay
