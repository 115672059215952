import Panel from 'components/panel/Panel'
import React from 'react'
import { Box, Building, CollectionPlay, People } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import ActionRow from './ActionRow'
import styles from './Dashboard.module.scss'

function SystemUserDashboard() {
  return (
    <Container>
      <div>
        <Panel>
          <ActionRow
            title='Manage Distributors'
            icon={<Building className={styles.actionIcon} />}
            color='#bdeeba'
            to='/distributors'>
            Manage and create distributors to use the system.
          </ActionRow>
          <ActionRow
            title='Manage Packages'
            icon={<Box className={styles.actionIcon} />}
            color='#fffbc7'
            to='/packages'>
            Manage and create billing packages.
          </ActionRow>
          <ActionRow
            title='Manage Admin Users'
            icon={<People className={styles.actionIcon} />}
            color='#f4b9a2'
            to='/users'>
            Setup additional admin user to manage Visivo Connect.
          </ActionRow>
          <ActionRow
            title='Cloudinary'
            icon={<CollectionPlay className={styles.actionIcon} />}
            color='#abc0f3'
            to='https://cloudinary.com/users/login'
            aLink>
            Media is stored on Cloudinary, you can view your usage and quotas on
            Cloudinary.
          </ActionRow>
        </Panel>
      </div>
    </Container>
  )
}

export default SystemUserDashboard
