import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { AuthContext } from 'contexts/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { requestIncreaseLimits } from 'api/request-increase-limits'

function UsageBanner({ used, limit, exceededLimit, children }) {
  const { user } = useContext(AuthContext)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)
  const [error, setError] = useState()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleChange = e => {
    setMessage(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setError('')
    setLoading(true)

    const res = await requestIncreaseLimits({ message: message })

    if (res.success) {
      setSent(true)
    } else {
      setError('There was an error sending the request')
    }

    setLoading(false)
  }

  if (limit === null) {
    return null
  } else {
    return (
      <React.Fragment>
        <Alert variant={exceededLimit ? 'warning' : 'info'}>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <strong>{used}</strong> of <strong>{limit}</strong>
              {children}.
            </div>
            <div>
              {exceededLimit && user.role === 'customer' && (
                <Button size='sm' variant='primary' onClick={handleShow}>
                  Increase Limits
                </Button>
              )}
            </div>
          </div>
        </Alert>

        <Modal size='lg' show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Increase Limits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {sent ? (
              <Alert variant='success'>Your request has been sent.</Alert>
            ) : (
              <React.Fragment>
                <div className='mb-4'>
                  If you would like to purchase more data storage or display capacity
                  please enter details below and one of our team will be in touch shortly.
                </div>

                <Form onSubmit={handleSubmit}>
                  <Form.Group className='mb-4'>
                    <Form.Group>
                      <Form.Control
                        name='message'
                        as='textarea'
                        rows={4}
                        value={message}
                        placeholder='What would like you increased?'
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form.Group>

                  {error && <Alert variant='danger'>{error}</Alert>}
                </Form>
              </React.Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} variant='secondary' onClick={handleClose}>
              Close
            </Button>
            {!sent && (
              <Button disabled={loading} variant='success' onClick={handleSubmit}>
                Submit Request
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

UsageBanner.propTypes = {
  used: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exceededLimit: PropTypes.bool,
}

UsageBanner.defaultProps = {
  exceededLimit: false,
}

export default UsageBanner
