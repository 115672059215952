import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PublicRoute from 'components/routes/PublicRoute'
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute'
import CustomerCreate from 'containers/customers/Create'
import CustomerUpdate from 'containers/customers/Update'
import ResellerStatusDashboard from 'containers/dashboard/ResellerStatusDashboard'
import DistributorCreate from 'containers/distributors/Create'
import DistributorUpdate from 'containers/distributors/Update'
import Login from 'containers/login/Login'
import NotFound from 'containers/not-found/NotFound'
import ResellerCreate from 'containers/resellers/Create'
import ResellerUpdate from 'containers/resellers/Update'
import { Route, Switch } from 'react-router-dom'
import Create from './containers/create/Create'
import Customers from './containers/customers/Customers'
import Dashboard from './containers/dashboard/Dashboard'
import Distributors from './containers/distributors/Distributors'
import Media from './containers/media/Media'
import MyProfile from './containers/my-profile/MyProfile'
import PackageForm from './containers/packages/Form'
import Packages from './containers/packages/Packages'
import PasswordResetEmailForm from './containers/password-reset/EmailForm'
import PasswordResetPasswordForm from './containers/password-reset/PasswordForm'
import PlaylistEdit from './containers/playlists/PlaylistEdit'
import Playlists from './containers/playlists/Playlists'
import Resellers from './containers/resellers/Resellers'
import SchedulesForm from './containers/schedules/Form'
import Schedules from './containers/schedules/Schedules'
import ScreenForm from './containers/screens/Form'
import Screens from './containers/screens/Screens'
import UserForm from './containers/users/Form'
import Users from './containers/users/Users'

const routes = () => {
  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={Dashboard} />
      <AuthenticatedRoute
        roles={['customer']}
        path='/create/:playlistId?/:slideId?'
        exact
        component={Create}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/displays/create/:folderId?'
        exact
        component={ScreenForm}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/displays/update/:id'
        exact
        component={ScreenForm}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/displays/:folderId?'
        exact
        component={Screens}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/playlists/:folderId?'
        exact
        component={Playlists}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/playlists/view/:id'
        exact
        component={PlaylistEdit}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/schedules'
        exact
        component={Schedules}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/schedules/update/:id'
        exact
        component={SchedulesForm}
      />
      <AuthenticatedRoute
        roles={['customer']}
        path='/schedules/create'
        exact
        component={SchedulesForm}
      />

      <AuthenticatedRoute
        roles={['customer']}
        path='/media/:folderId?'
        exact
        component={Media}
      />
      <AuthenticatedRoute path='/users' exact component={Users} />
      <AuthenticatedRoute path='/users/create' exact component={UserForm} />
      <AuthenticatedRoute path='/users/update/:id' exact component={UserForm} />

      <AuthenticatedRoute
        roles={['system_user', 'distributor', 'reseller']}
        path='/packages'
        exact
        component={Packages}
      />
      <AuthenticatedRoute
        roles={['system_user']}
        path='/packages/create'
        exact
        component={PackageForm}
      />
      <AuthenticatedRoute
        roles={['system_user', 'distributor', 'reseller']}
        path='/packages/update/:id'
        exact
        component={PackageForm}
      />

      <AuthenticatedRoute
        roles={['system_user']}
        path='/distributors'
        exact
        component={Distributors}
      />
      <AuthenticatedRoute
        roles={['system_user']}
        path='/distributors/create'
        exact
        component={DistributorCreate}
      />
      <AuthenticatedRoute
        roles={['system_user']}
        path='/distributors/update/:id'
        exact
        component={DistributorUpdate}
      />

      <AuthenticatedRoute
        roles={['distributor']}
        path='/resellers'
        exact
        component={Resellers}
      />
      <AuthenticatedRoute
        roles={['distributor']}
        path='/resellers/create'
        exact
        component={ResellerCreate}
      />
      <AuthenticatedRoute
        roles={['distributor']}
        path='/resellers/update/:id'
        exact
        component={ResellerUpdate}
      />

      <AuthenticatedRoute
        roles={['reseller']}
        path='/customers'
        exact
        component={Customers}
      />
      <AuthenticatedRoute
        roles={['reseller']}
        path='/customers/create'
        exact
        component={CustomerCreate}
      />
      <AuthenticatedRoute
        roles={['reseller']}
        path='/customers/update/:id'
        exact
        component={CustomerUpdate}
      />

      <AuthenticatedRoute
        roles={['system_user', 'distributor', 'reseller']}
        path='/users/:ownerType/:ownerId'
        exact
        component={Users}
      />
      <AuthenticatedRoute
        roles={['system_user', 'distributor', 'reseller']}
        path='/users/create/:ownerType/:ownerId'
        exact
        component={UserForm}
      />

      <AuthenticatedRoute path='/my-profile' exact component={MyProfile} />

      <UnauthenticatedRoute
        path='/password/reset'
        exact
        component={PasswordResetEmailForm}
      />
      <UnauthenticatedRoute
        path='/password/reset/:token'
        exact
        component={PasswordResetPasswordForm}
      />

      <PublicRoute
        path='/device-dashboard/:hash'
        exact
        component={ResellerStatusDashboard}
      />

      <UnauthenticatedRoute path='/login' exact component={Login} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default routes
