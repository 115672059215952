import BaseLayer from 'components/create/BaseLayer'
import Overlay from 'components/create/Overlay'
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext'
import { CreateContext } from 'contexts/CreateContext'
import React, { useContext, useEffect } from 'react'

function Create() {
  const { state, playlistId, slideId } = useContext(CreateContext)
  const { setBreadcrumbs } = useContext(BreadcrumbsContext)

  useEffect(() => {
    const breadcrumbs = [
      {
        label: playlistId ? 'Existing Playlist' : 'New Playlist',
        href: playlistId ? `/playlists/view/${playlistId}` : '#',
        active: playlistId ? false : true,
      },
      { label: slideId ? 'Existing Slide' : 'New Slide', href: '#', active: true },
      { label: 'Base Layer', href: '#', active: true },
    ]

    if (state.baseLayer) {
      breadcrumbs.push({ label: 'Overlay', href: '#', active: true })
    }

    setBreadcrumbs(breadcrumbs)

    return () => {
      setBreadcrumbs([])
    }
  }, [setBreadcrumbs, playlistId, slideId, state.baseLayer])

  return state.baseLayer ? <Overlay /> : <BaseLayer />
}

export default Create
