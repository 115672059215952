import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function DisplaysMutedHelp(props) {
  return (
    <Tooltip
      label={
        <>
          Most desktop browsers will block autoplaying videos if they are not muted. If
          you select no for displays muted it may break the display unless the device does
          not block by default or the browser has specifically allowed Visivo Connect to
          playback unmuted.
        </>
      }>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default DisplaysMutedHelp
