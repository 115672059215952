import { list as listMedia } from 'api/media'
import CreateADigitalDisplay from 'components/CreateADigitalDisplay'
import Grid from 'components/grid/Grid'
import SmallHeader from 'components/headers/SmallHeader'
import Loader from 'components/Loader'
import MediaItem from 'components/media/MediaItem'
import Panel from 'components/panel/Panel'
import React, { useEffect, useState } from 'react'
import { CollectionPlay, People, Tv } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import ActionRow from './ActionRow'
import styles from './Dashboard.module.scss'

function CustomerDashboard() {
  const [loading, setLoading] = useState(true)
  const [media, setMedia] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const res = await listMedia(4)
      setMedia(res)
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <Container>
      <div className={styles.topGrid}>
        <CreateADigitalDisplay />
        <Panel>
          <ActionRow
            title='Manage Displays'
            icon={<Tv className={styles.actionIcon} />}
            color='#bdeeba'
            to='/displays'>
            Setup a display and select the playlist you would like it to display.
          </ActionRow>
          <ActionRow
            title='Manage Users'
            icon={<People className={styles.actionIcon} />}
            color='#f4b9a2'
            to='/users'>
            Setup additional users for Visivo connect, allowing others to help create
            unique displays.
          </ActionRow>
          <ActionRow
            title='Media Library'
            icon={<CollectionPlay className={styles.actionIcon} />}
            color='#abc0f3'
            to='/media'>
            Manage and upload your media files to create unique displays.
          </ActionRow>
        </Panel>
      </div>

      <Link to='/media'>
        <Panel>
          <SmallHeader>Media Library</SmallHeader>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {media.data.length === 0 ? (
                <div>You haven't uploaded any media yet.</div>
              ) : (
                <Grid>
                  {media.data.map(x => (
                    <MediaItem key={x.public_id} resource={x} />
                  ))}
                </Grid>
              )}
            </React.Fragment>
          )}
        </Panel>
      </Link>
    </Container>
  )
}

export default CustomerDashboard
