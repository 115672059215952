import { update } from 'api/folders'
import LoaderButton from 'components/LoaderButton'
import Tooltip from 'components/Tooltip'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

function UpdateFolder({ folderId, defaultName, onSuccess }) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(defaultName)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setName(defaultName)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const res = await update(folderId, { name: name })
    if (res.success) {
      setLoading(false)
      if (onSuccess !== undefined) {
        setLoading(false)
        setOpen(false)
        onSuccess()
      }
    } else {
      setError(res.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Tooltip label='Update folder'>
        <Button variant='primary' className='mr-1 px-2 mb-1' onClick={handleOpen}>
          <Pencil />
        </Button>
      </Tooltip>

      <Modal show={open} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className='mb-4'>
              <Form.Group>
                <Form.Control
                  name='name'
                  placeholder='Folder name'
                  value={name}
                  onChange={e => {
                    setError('')
                    setName(e.target.value)
                  }}
                />
              </Form.Group>
            </Form.Group>

            {error && (
              <Alert className='mt-4' variant='danger'>
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <LoaderButton
              label='Update'
              loading={loading}
              variant='primary'
              type='submit'
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

UpdateFolder.propTypes = {
  folderId: PropTypes.string.isRequired,
  defaultName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
}

export default UpdateFolder
