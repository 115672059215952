import { dashboard } from 'api/resellers'
import Loader from 'components/Loader'
import Panel from 'components/panel/Panel'
import { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { useParams } from 'react-router-dom'

function ResellerStatusDashboard() {
  const { hash } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const res = await dashboard(hash)
      setData(res)
    }

    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })

    const refetchInterval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(refetchInterval)
  }, [hash])

  return (
    <Container className='p-4'>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Panel className='mb-4'>
            <h2 className='text-center'>Overview</h2>
            <Row className='font-weight-bold'>
              <Col className='text-success text-center'>
                <Row>
                  <Col>Online Devices: </Col>
                </Row>
                <Row>
                  <Col className='font-size-xl'>{data.data.online_devices}</Col>
                </Row>
              </Col>
              <Col className='text-danger text-center'>
                <Row>
                  <Col>Offline Devices: </Col>
                </Row>
                <Row>
                  <Col className='font-size-xl'>{data.data.offline_devices}</Col>
                </Row>
              </Col>
            </Row>
          </Panel>

          <Panel>
            <h2 className='text-center'>Offline Sites</h2>
            <Table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Screens Offline</th>
                  <th>Longest Offline Duration</th>
                </tr>
              </thead>
              <tbody>
                {data.data.offline_sites.map((s, i) => (
                  <tr key={`offline-site-${i}`}>
                    <td>{s.name}</td>
                    <td>{s.screens_offline}</td>
                    <td>{s.offline_duration}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
        </>
      )}
    </Container>
  )
}

export default ResellerStatusDashboard
