import { create, get, update } from 'api/users'
import LargeHeader from 'components/headers/LargeHeader'
import LoaderButton from 'components/LoaderButton'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router'

function Edit(props) {
  const { id, ownerType, ownerId } = useParams()
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const _user = await get(id)
        setValues({
          first_name: _user.data.first_name,
          last_name: _user.data.last_name,
          email: _user.data.email,
        })
      }

      setInitialLoading(false)
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMessage('')
    setErrors({})
    setLoading(true)

    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    }

    if (ownerId && ownerId !== undefined) {
      body.ownerType = ownerType
      body.ownerId = ownerId
    }

    const res = id ? await update(id, body) : await create(body)

    if (res.success) {
      props.history.goBack()
    } else {
      setErrorMessage(res.data.message)
      setErrors(res.data.errors ?? {})
    }

    setLoading(false)
  }

  if (initialLoading) {
    return null
  }

  return (
    <Container>
      <LargeHeader>{id ? 'Update' : 'Create'} User</LargeHeader>

      <Form onSubmit={handleSubmit}>
        <div className='mb-4'>
          Setup additional users for Visivo connect, allowing others to help create unique
          displays.
        </div>

        <Form.Group className='mb-4'>
          <Form.Group>
            <Form.Control
              isInvalid={errors.hasOwnProperty('first_name')}
              name='first_name'
              value={values.first_name}
              placeholder='First name'
              onChange={handleChange}
            />
            {errors.hasOwnProperty('first_name') && (
              <Form.Text className='text-danger'>{errors.first_name}</Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Control
              isInvalid={errors.hasOwnProperty('last_name')}
              name='last_name'
              value={values.last_name}
              placeholder='Last name'
              onChange={handleChange}
            />
            {errors.hasOwnProperty('last_name') && (
              <Form.Text className='text-danger'>{errors.last_name}</Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Control
              isInvalid={errors.hasOwnProperty('email')}
              name='email'
              type='email'
              value={values.email}
              placeholder='Email'
              onChange={handleChange}
            />
            {errors.hasOwnProperty('email') && (
              <Form.Text className='text-danger'>{errors.email}</Form.Text>
            )}
          </Form.Group>
        </Form.Group>

        {errorMessage && (
          <Alert className='mt-4' variant='danger'>
            {errorMessage}
          </Alert>
        )}

        <Button
          variant='secondary'
          className='mt-4 mr-2'
          onClick={() => props.history.goBack()}>
          Cancel
        </Button>

        <LoaderButton
          label='Save'
          className='mt-4'
          loading={loading}
          variant='success'
          type='submit'
        />
      </Form>
    </Container>
  )
}

export default Edit
