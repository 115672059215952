import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function UserLimitHelp(props) {
  return (
    <Tooltip label='The customer will be able to create their own users to login and manage displays. Limit the number of users that can be created.'>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default UserLimitHelp
