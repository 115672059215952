import LoaderButton from 'components/LoaderButton'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

function RefreshModal({ open, onHide, loading, onSubmit, children }) {
  return (
    <Modal
      size='lg'
      show={open}
      onHide={onHide}
      aria-labelledby='example-modal-sizes-title-lg'>
      <Modal.Header closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>Refresh Displays</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          Any displays that are using this playlist will will be alerted that there are
          changes pending. These displays will then refresh automatically, It may take up
          to 10 minutes for this to happen.
        </div>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <LoaderButton
          loading={loading}
          label='Confirm'
          onClick={onSubmit}
          variant='success'
        />
      </Modal.Footer>
    </Modal>
  )
}

RefreshModal.propTypes = {
  open: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

RefreshModal.defaultProps = {
  open: false,
  loading: false,
}

export default RefreshModal
